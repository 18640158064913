import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  all: null,
  active: null,
  pending: true,
  touched: false,
};

const facilityUnitsSlice = createSlice({
  name: 'facilityUnits',
  initialState,
  reducers: {
    setAll: (state, action) => {
      state.all = action.payload;
    },
    setActive: (state, action) => {
      state.active = action.payload;
    },
    setPending: (state, action) => {
      state.pending = action.payload;
    },
    setTouched: (state, action) => {
      state.touched = action.payload;
    },
    forgetState: (state, action) => {
      state.all = initialState.all;
      state.active = initialState.active;
      state.pending = initialState.pending;
      state.touched = initialState.touched;
    },
  },
});

export const facilityUnitsActions = facilityUnitsSlice.actions;

export default facilityUnitsSlice.reducer;
