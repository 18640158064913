import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  purchaseOrder: {data: null, pending: true},
  addedMaterials: [],
  addedSubMaterials: [],
  currentProfile: {data: null, pending: true},
  profileMaterials: {data: null, pending: true},
};

const purchaseOrderDetailsSlice = createSlice({
  name: 'purchaseOrderDetails',
  initialState,
  reducers: {
    setPurchaseOrder: (state, action) => {
      state.purchaseOrder = {...state.purchaseOrder, ...action.payload};
    },
    setAddedMaterials: (state, action) => {
      state.addedMaterials = [...action.payload];
    },
    setAddedSubMaterials: (state, action) => {
      state.addedSubMaterials = [...action.payload];
    },
    setCurrentProfile: (state, action) => {
      state.currentProfile = {...state.currentProfile, ...action.payload};
    },
    setProfileMaterials: (state, action) => {
      state.profileMaterials = {...state.profileMaterials, ...action.payload};
    },
    resetInitialState: (state, action) => {
      state.purchaseOrder = {data: null, pending: true};
      state.addedMaterials = [];
      state.addedSubMaterials = [];
      state.currentProfile = {data: null, pending: true};
      state.profileMaterials = {data: null, pending: true};
    },
  },
});

export const purchaseOrderDetailsActions = purchaseOrderDetailsSlice.actions;

export default purchaseOrderDetailsSlice.reducer;
