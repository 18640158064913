import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Auth, Firestore} from 'firebaseConfig';
import {productionActions} from 'state/production/slice';
import {DateTime} from 'luxon';
const snapOptions = {includeMetadataChanges: true};

const recentDate = DateTime.now().minus({days: 30}).startOf('day').toJSDate();

let consolidateUnsubscribe = null;
let kbiMaterialUnsubscribe = null;
let processUnsubscribe = null;
let sortFullUnsubscribe = null;
let sortQuickUnsubscribe = null;

const useProduction = () => {
  const {consolidation, process, sortFull, sortQuick, kbiMaterial, touched} = useSelector(state => state.production);
  const dispatch = useDispatch();

  useEffect(() => {
    // Only run remainder of code if hook untouched; Prevents duplicate invocations;
    if (touched) return;
    dispatch(productionActions.setTouched(true));

    // Make sure user is authenticated before invoking listener
    Auth.onAuthStateChanged(user => {
      // USER NOT AUTHENTICATED
      // When user is no longer authenticate, clear redux state and unsubscribe to listener
      if (!user) {
        dispatch(productionActions.forgetState());
        if (consolidateUnsubscribe) consolidateUnsubscribe();
        if (sortFullUnsubscribe) sortFullUnsubscribe();
        if (sortQuickUnsubscribe) sortQuickUnsubscribe();
        if (processUnsubscribe) processUnsubscribe();
        if (kbiMaterialUnsubscribe) kbiMaterialUnsubscribe();
      }

      // USER IS AUTHENTICATED

      // RECENT CONSOLIDATION FORMS
      // Query Production-Consolidate and return all documents greater than the recentDate property
      const consolidateQuery = Firestore.query(
        Firestore.collection('Production-Consolidate'),
        Firestore.where('TimeCreated', '>', recentDate),
      );
      const consolidateSnap = snap => {
        const {fromCache, hasPendingWrites} = snap?.metadata || {};
        if (fromCache || hasPendingWrites) return;

        const snapData = [];
        snap.forEach(doc => {
          const docData = doc.data();

          const cleanDoc = {
            ...docData,
            'DocId': doc.id,
            'ConsolContainer': (() => {
              if (!snapData?.ConsolContainer) return null;
              return {
                ...snapData?.ConsolContainer,
                AccumStartDate: snapData?.ConsolContainer.AccumStartDate.toMillis(),
              };
            })(),
            'TimeCreated': docData?.TimeCreated?.toMillis ? docData.TimeCreated.toMillis() : null,
            'TimeCompleted': docData?.TimeCompleted?.toMillis ? docData.TimeCompleted.toMillis() : null,
            'System': {
              ...docData.System,
              EnteredOn: docData?.System?.EnteredOn?.toMillis ? docData.System.EnteredOn.toMillis() : null,
            },
          };
          snapData.push(cleanDoc);
        });
        dispatch(productionActions.setConsolidation({data: snapData, pending: false}));
      };
      const consolidateError = error => {
        if (error.message !== 'Missing or insufficient permissions.') alert(error.message);
        console.log('useProduction consolidateSnapError: ', error.message, {error});
      };
      consolidateUnsubscribe = Firestore.onSnapshot(consolidateQuery, snapOptions, consolidateSnap, consolidateError);

      // RECENT PROCESS FORMS
      // Query Production-Process and return all documents greater than the recentDate property
      const processQuery = Firestore.query(
        Firestore.collection('Production-Process'),
        Firestore.where('TimeCreated', '>', recentDate),
      );
      const processSnap = snap => {
        const {fromCache, hasPendingWrites} = snap?.metadata || {};
        if (fromCache || hasPendingWrites) return;

        const snapData = [];
        snap.forEach(doc => {
          const docData = doc.data();
          const cleanDoc = {
            ...docData,
            'DocId': doc.id,
            'Accounting': (() => {
              if (!snapData?.Accounting) return null;
              return {
                ...snapData.Accounting,
                DateEntered: snapData?.Accounting?.DateEntered ? snapData.Accounting.DateEntered.toMillis() : null,
              };
            })(),
            'TimeCreated': docData?.TimeCreated?.toMillis ? docData.TimeCreated.toMillis() : null,
            'TimeCompleted': docData?.TimeCompleted?.toMillis ? docData.TimeCompleted.toMillis() : null,
            'System': {
              ...docData.System,
              EnteredOn: docData?.System?.EnteredOn?.toMillis ? docData.System.EnteredOn.toMillis() : null,
            },
          };
          snapData.push(cleanDoc);
        });
        dispatch(productionActions.setProcess({data: snapData, pending: false}));
      };
      const processError = error => {
        if (error.message !== 'Missing or insufficient permissions.') alert(error.message);
        console.log('useProduction processError: ', error.message, {error});
      };
      processUnsubscribe = Firestore.onSnapshot(processQuery, snapOptions, processSnap, processError);

      // RECENT FULL SORT FORMS
      // Query Production-Sort-Full and return all documents greater than the recentDate property
      const sortFullQuery = Firestore.query(
        Firestore.collection('Production-Sort-Full'),
        Firestore.where('TimeCreated', '>', recentDate),
      );
      const sortFullSnap = snap => {
        const {fromCache, hasPendingWrites} = snap?.metadata || {};
        if (fromCache || hasPendingWrites) return;

        const snapData = [];
        snap.forEach(doc => {
          const docData = doc.data();
          const cleanDoc = {
            ...docData,
            DocId: doc.id,
            InboundContainer: (() => {
              const {InboundContainer} = snapData;

              if (!InboundContainer) return null;
              return {
                ...snapData.InboundContainer,
                AccumStartDate: InboundContainer?.AccumStartDate?.toMillis ? InboundContainer.AccumStartDate.toMillis() : null,
              };
            })(),
            TimeCreated: docData?.TimeCreated?.toMillis ? docData.TimeCreated.toMillis() : null,
            TimeCompleted: docData?.TimeCompleted?.toMillis ? docData.TimeCompleted.toMillis() : null,
            System: {
              ...docData.System,
              EnteredOn: docData?.System?.EnteredOn?.toMillis ? docData.System.EnteredOn.toMillis() : null,
            },
          };
          snapData.push(cleanDoc);
        });
        dispatch(productionActions.setSortFull({data: snapData, pending: false}));
      };
      const sortFullError = error => {
        if (error.message !== 'Missing or insufficient permissions.') alert(error.message);
        console.log('useProduction fullSortError: ', error.message, {error});
      };
      sortFullUnsubscribe = Firestore.onSnapshot(sortFullQuery, snapOptions, sortFullSnap, sortFullError);

      // RECENT QUICK SORT FORMS
      // Query Production-Sort-Quick and return all documents greater than the recentDate property
      const sortQuickQuery = Firestore.query(
        Firestore.collection('Production-Sort-Quick'),
        Firestore.where('TimeCreated', '>', recentDate),
      );
      const sortQuickSnap = snap => {
        const {fromCache, hasPendingWrites} = snap?.metadata || {};
        if (fromCache || hasPendingWrites) return;

        const snapData = [];
        snap.forEach(doc => {
          const docData = doc.data();
          const cleanDoc = {
            ...docData,
            'DocId': doc.id,
            'TimeCreated': docData?.TimeCreated?.toMillis ? docData.TimeCreated.toMillis() : null,
            'TimeCompleted': docData?.TimeCompleted?.toMillis ? docData.TimeCompleted.toMillis() : null,
            'System': {
              ...docData.System,
              EnteredOn: docData?.System?.EnteredOn?.toMillis ? docData.System.EnteredOn.toMillis() : null,
            },
          };
          snapData.push(cleanDoc);
        });
        dispatch(productionActions.setSortQuick({data: snapData, pending: false}));
      };
      const quckSortError = error => {
        if (error.message !== 'Missing or insufficient permissions.') alert(error.message);
        console.log('useProduction quckSortError: ', error.message, {error});
      };
      sortQuickUnsubscribe = Firestore.onSnapshot(sortQuickQuery, snapOptions, sortQuickSnap, quckSortError);

      // RECENT ADD KBI MATERIALS FORMS
      // Query Production-Add-Material and return all documents greater than the recentDate property
      const kbiMaterialQuery = Firestore.query(
        Firestore.collection('Production-Add-Material'),
        Firestore.where('TimeCreated', '>', recentDate),
      );
      const kbiMaterialSnap = snap => {
        const {fromCache, hasPendingWrites} = snap?.metadata || {};
        if (fromCache || hasPendingWrites) return;

        const snapData = [];
        snap.forEach(doc => {
          const docData = doc.data();
          const cleanDoc = {
            ...docData,
            DocId: doc.id,
            Accounting: (() => {
              if (!snapData?.Accounting) return null;
              return {
                ...snapData.Accounting,
                DateEntered: snapData?.Accounting?.DateEntered ? snapData.Accounting.DateEntered.toMillis() : null,
              };
            })(),
            TimeCreated: docData?.TimeCreated?.toMillis ? docData.TimeCreated.toMillis() : null,
            TimeCompleted: docData?.TimeCompleted?.toMillis ? docData.TimeCompleted.toMillis() : null,
            System: {
              ...docData.System,
              EnteredOn: docData?.System?.EnteredOn?.toMillis ? docData.System.EnteredOn.toMillis() : null,
            },
          };
          snapData.push(cleanDoc);
        });
        dispatch(productionActions.setKbiMaterial({data: snapData, pending: false}));
      };
      const kbiMaterialError = error => {
        if (error.message !== 'Missing or insufficient permissions.') alert(error.message);
        console.log('useProduction kbiMaterialError: ', error.message, {error});
      };
      kbiMaterialUnsubscribe = Firestore.onSnapshot(kbiMaterialQuery, snapOptions, kbiMaterialSnap, kbiMaterialError);
    });
  }, [dispatch, touched]);

  return {
    consolidation: consolidation.data,
    kbiMaterial: kbiMaterial.data,
    process: process.data,
    sortFull: sortFull.data,
    sortQuick: sortQuick.data,
    pending: Boolean(
      consolidation.pending ||
      kbiMaterial.pending ||
      process.pending ||
      sortFull.pending ||
      sortQuick.pending
    ),
    touched,
  };
};

export default useProduction;
