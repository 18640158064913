import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  shippingDoc: {data: null, pending: true},
  proformaItems: null,
  addlEntries: null,
  inboundPieces: {data: null, pending: true},
  selectedInboundPiece: null,
};

const proformaDetailsSlice = createSlice({
  name: 'proformaDetails',
  initialState,
  reducers: {
    setShippingDoc: (state, action) => {
      state.shippingDoc = {...state.shippingDoc, ...action.payload};
    },
    setProformaItems: (state, action) => {
      state.proformaItems = action.payload;
    },
    setAddlEntries: (state, action) => {
      state.addlEntries = action.payload;
    },
    setInboundPieces: (state, action) => {
      state.inboundPieces = {...state.inboundPieces, ...action.payload};
    },
    setSelectedInboundPiece: (state, action) => {
      state.selectedInboundPiece = action.payload;
    },
    resetInitialState: (state, action) => {
      state.setShippingDoc = initialState.setShippingDoc;
      state.proformaItems = initialState.proformaItems;
      state.inboundPieces = initialState.inboundPieces;
      state.selectedInboundPiece = initialState.selectedInboundPiece;
    },
  },
});

export const proformaDetailsActions = proformaDetailsSlice.actions;

export default proformaDetailsSlice.reducer;
