import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  outbound: {data: null, pending: true},
  lines: {data: null, map: null, pending: true},
  pieces: {data: null, byLine: null, pending: true},
  files: {data: null, pending: true},
  addlTransporters: {data: null, pending: true},
};

const outboundDetailsSlice = createSlice({
  name: 'outboundDetails',
  initialState,
  reducers: {
    setOutbound: (state, action) => {
      state.outbound = {...state.outbound, ...action.payload};
    },
    setLines: (state, action) => {
      state.lines = {...state.lines, ...action.payload};
    },
    setPieces: (state, action) => {
      state.pieces = {...state.pieces, ...action.payload};
    },
    setFiles: (state, action) => {
      state.files = {...state.files, ...action.payload};
    },
    setAddlTransporters: (state, action) => {
      state.addlTransporters = {...state.addlTransporters, ...action.payload};
    },
    resetInitialState: (state, action) => {
      state.outbound = initialState.outbound;
      state.lines = initialState.lines;
      state.pieces = initialState.pieces;
      state.files = initialState.files;
      state.addlTransporters = initialState.addlTransporters;
    },
  },
});

export const outboundDetailsActions = outboundDetailsSlice.actions;

export default outboundDetailsSlice.reducer;
