import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  index: [],
  pending: true,
  touched: false,
};

const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    setIndex: (state, action) => {
      state.index = action.payload;
    },
    setPending: (state, action) => {
      state.pending = action.payload;
    },
    setTouched: (state, action) => {
      state.touched = action.payload;
    },
    forgetState: (state, action) => {
      state.index = initialState.index;
      state.pending = initialState.pending;
      state.touched = initialState.touched;
    },
  },
});

export const accountActions = accountsSlice.actions;

export default accountsSlice.reducer;
