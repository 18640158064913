import React from 'react';
import PropTypes from 'prop-types';
import {List, ListItem, Typography} from '@mui/material';
import {Link} from 'react-router-dom';

const DetailsTitle = props => {
  // Default Props
  const titleVariant = props?.titleVariant || 'h6';
  const subtitleVariant = props?.subtitleVariant || 'body2';

  return (
    <List disablePadding sx={{...styles.list, marginTop: props?.icon ? '-16px' : '0px'}}>
      <ListItem dense sx={{flexDirection: 'column'}}>
        <Typography variant={titleVariant} sx={{...styles.basicText, ...props.titleStyle}}>
          {props?.title || ''}
        </Typography>
        {props?.subtitle && !props?.subtitleLink && (
          <Typography variant={subtitleVariant} sx={{...styles.basicText, ...props.subtitleStyle}}>
            {props.subtitle}
          </Typography>
        )}
        {props?.subtitle && props?.subtitleLink && (
          <Link to={props.subtitleLink}>
            <Typography variant='body2' sx={{...styles.basicText, color: '#ed1c24'}}>{props.subtitle}</Typography>
          </Link>
        )}
      </ListItem>
    </List>
  );
};

const styles = {
  list: {
    marginBottom: '16px',
  },
  basicText: {
    lineHeigt: '.9',
    textAlign: 'center',
  },
};
DetailsTitle.propTypes = {
  /** The icon to be used above the title */
  icon: PropTypes.elementType,
  /** The title of the section */
  title: PropTypes.string.isRequired,
  /** Any one of the typography variants of MaterialUI to be applied to the title */
  titleVariant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'subtitle1', 'subtitle2', 'body1', 'body2']),
  /** Any styling that will be applied to the title Typography */
  titleStyle: PropTypes.object,
  /** The sting displayed as a subtitle */
  subtitle: PropTypes.string,
  /** Any styling that will be applied to the subtitle Typography */
  subtitleStyle: PropTypes.object,
  /** Any one of the typography variants of MaterialUI to be applied to the subtitle */
  subtitleVariant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'subtitle1', 'subtitle2', 'body1', 'body2']),
  /** If the subtitle is a link, this holds the url */
  subtitleLink: PropTypes.string,
};
export default DetailsTitle;
