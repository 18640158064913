import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  consolidateForm: {data: null, pending: true},
  mergeContainers: {data: null, pending: true},
  totalMergeWeight: null,
  oldestAccumDate: null,
};

const consolidateDetailsSlice = createSlice({
  name: 'consolidateDetails',
  initialState,
  reducers: {
    setConsolidateForm: (state, action) => {
      state.consolidateForm = {...state.consolidateForm, ...action.payload};
    },
    setMergeContainers: (state, action) => {
      state.mergeContainers = {...state.mergeContainers, ...action.payload};
    },
    setTotalMergeWeight: (state, action) => {
      state.totalMergeWeight = action.payload;
    },
    setOldestAccumDate: (state, action) => {
      state.oldestAccumDate = action.payload;
    },
    resetInitialState: (state, action) => {
      state.consolidateForm = initialState.consolidateForm;
      state.mergeContainers = initialState.mergeContainers;
      state.totalMergeWeight = initialState.totalMergeWeight;
      state.oldestAccumDate = initialState.oldestAccumDate;
    },
  },
});

export const consolidateDetailsActions = consolidateDetailsSlice.actions;

export default consolidateDetailsSlice.reducer;
