import {createSlice} from '@reduxjs/toolkit';

export const selectors = ['All', 'Inbound', 'Outbound', 'Scheduled', 'In Progress', 'Completed', 'Cancelled'];
const initialState = {
  activeSelector: selectors[0],
};

const shipmentViewSlice = createSlice({
  name: 'shipmentView',
  initialState,
  reducers: {
    setActiveSelector: (state, action) => {
      state.activeSelector = action.payload;
    },
    forgetState: (state, action) => {
      state.activeSelector = initialState.activeSelector;
    },
  },
});

export const shipmentViewActions = shipmentViewSlice.actions;

export default shipmentViewSlice.reducer;
