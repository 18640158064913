import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  account: {data: null, pending: true},
  contacts: {data: null, pending: true},
  locations: {data: null, pending: true},
  notes: {data: null, pending: true},
  documents: {data: null, pending: true},
  profiles: {data: null, pending: true},
  purchaseOrders: {data: null, pending: true},
  shipmentsInbound: {data: null, pending: true},
  shipmentsOutbound: {data: null, pending: true},
};

const accountDetailsSlice = createSlice({
  name: 'accountDetails',
  initialState,
  reducers: {
    setAccount: (state, action) => {
      state.account = {...state.account, ...action.payload};
    },
    setContacts: (state, action) => {
      state.contacts = {...state.account, ...action.payload};
    },
    setLocations: (state, action) => {
      state.locations = {...state.locations, ...action.payload};
    },
    setNotes: (state, action) => {
      state.notes = {...state.notes, ...action.payload};
    },
    setDocuments: (state, action) => {
      state.documents = {...state.documents, ...action.payload};
    },
    setProfiles: (state, action) => {
      state.profiles = {...state.profiles, ...action.payload};
    },
    setPurchaseOrders: (state, action) => {
      state.purchaseOrders = {...state.purchaseOrders, ...action.payload};
    },
    setShipmentsInbound: (state, action) => {
      state.shipmentsInbound = {...state.shipmentsInbound, ...action.payload};
    },
    setShipmentsOutbound: (state, action) => {
      state.shipmentsOutbound = {...state.shipmentsOutbound, ...action.payload};
    },
    resetInitialState: (state, action) => {
      state.account = initialState.account;
      state.contacts = initialState.contacts;
      state.locations = initialState.locations;
      state.notes = initialState.notes;
      state.documents = initialState.documents;
      state.profiles = initialState.profiles;
      state.purchaseOrders = initialState.purchaseOrders;
      state.shipmentsInbound = initialState.shipmentsInbound;
      state.shipmentsOutbound = initialState.shipmentsOutbound;
    },
  },
});

export const accountDetailsActions = accountDetailsSlice.actions;

export default accountDetailsSlice.reducer;
