import React from 'react';
import {Navigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import {useUiSnackbar} from 'state';

const ErrorRedirect = ({error, path}) => {
  const {setSnackbar} = useUiSnackbar();
  console.log(`ERROR: "${error}"`);

  setTimeout(() => {
    // Set timeout to prevent conflicting renders from Navigate and Snackbar
    setSnackbar({open: true, message: `ERROR: "${error}"`, severity: 'error'});
  }, 150);

  return <Navigate replace to={path} />;
};

ErrorRedirect.propTypes = {
  error: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};
export default React.memo(ErrorRedirect);
