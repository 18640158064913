import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Auth, Functions} from 'firebaseConfig';
import {authActions} from 'state/auth/slice';

const useAuthAppUsers = () => {
  const {appUsers} = useSelector(state => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    // Prevent listener from running a second time
    dispatch(authActions.setAppUsers({touched: true}));

    // Make sure user is authenticated before getting data
    Auth.onAuthStateChanged(async user => {
      // USER NOT AUTHENTICATED
      // When user is no longer authenticate, clear redux state
      if (!user) dispatch(authActions.forgetState());

      // USER IS AUTHENTICATED
      // When user is authenticated, query cloud function and add data results to redux state
      try {
        const functionUrl = Functions.getFunctionURL('corpbatteriesuserlist');
        const {data} = await Functions.httpsCallableFromURL(functionUrl)();

        // Make sure all user have a displayName field to populate fields
        const cleanData = data.map(user => {
          return {...user, displayName: user?.displayName || user?.email || user.uid};
        });

        dispatch(authActions.setAppUsers({data: cleanData}));
      }
      catch (error) {
        if (error.message !== 'Missing or insufficient permissions.') alert(error.message);
        console.log('hooks/state/useAuthGetAppUsers Error: ', error.message, {error});
      }
    });
  }, [dispatch]);

  return appUsers;
};

export default useAuthAppUsers;
