import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Auth, Firestore} from 'firebaseConfig';
import {kbiLocationsActions} from 'state/admin/slices/kbiLocations';
const snapOptions = {includeMetadataChanges: true};

const useAdminKbiLocations = () => {
  const kbiLocations = useSelector(state => state.admin.kbiLocations);
  const dispatch = useDispatch();

  useEffect(() => {
    // Only run remainder of code if hook untouched; Prevents duplicate invocations;
    if (kbiLocations.touched) return;
    dispatch(kbiLocationsActions.setTouched(true));

    let unsubscribe = null;
    // Make sure user is authenticated before invoking listener
    Auth.onAuthStateChanged(user => {
      // USER NOT AUTHENTICATED
      // When user is no longer authenticate, clear redux state and unsubscribe to listener
      if (!user) {
        dispatch(kbiLocationsActions.forgetState());
        if (unsubscribe) unsubscribe();
      }

      // USER IS AUTHENTICATED
      // When user is authenticated, create snapshot listener and add data results to redux state
      const query = Firestore.query(
        Firestore.collection('Admin-Kbi-Locations'),
        Firestore.orderBy('Name')
      );
      const snapData = snap => {
        const {fromCache, hasPendingWrites} = snap?.metadata || {};
        if (fromCache || hasPendingWrites) return;

        const data = {all: [], active: []};

        // Don't do anthing when snap still has pending writes; Only update UI when all writes complete
        if (snap?.metadata?.hasPendingWrites) return;

        // If snap is empty and redux state is pending, then set pending to false to show first snap performed
        if (snap.empty && kbiLocations.pending) {
          dispatch(kbiLocationsActions.setData(data));
          dispatch(kbiLocationsActions.setPending(false));
          return;
        }

        snap.forEach(doc => {
          const docData = doc.data();
          const cleanData = {
            ...docData,
            DocId: doc.id,
            System: {
              ...docData.System,
              EnteredOn: docData.System.EnteredOn.toMillis(),
            },
          };
          data.all.push(cleanData);
          if (docData.Active) data.active.push(cleanData);
        });

        dispatch(kbiLocationsActions.setData(data));
        dispatch(kbiLocationsActions.setPending(false));
      };
      const snapError = error => {
        if (error.message !== 'Missing or insufficient permissions.') alert(error.message);
        console.log('useAdminKbiLocations Error: ', error.message, {error});
      };
      unsubscribe = Firestore.onSnapshot(query, snapOptions, snapData, snapError);
    });
  }, [kbiLocations, dispatch]);

  return kbiLocations;
};

export default useAdminKbiLocations;
