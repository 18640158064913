import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  sortForm: {data: null, pending: true},
  sortContainers: {data: null, pending: true},
  sortImages: {data: null, pending: true},
  purchaseOrder: {data: null, pending: true},
};

const sortFullDetailsSlice = createSlice({
  name: 'sortFullDetails',
  initialState,
  reducers: {
    setSortForm: (state, action) => {
      state.sortForm = {...state.sortForm, ...action.payload};
    },
    setSortContainers: (state, action) => {
      state.sortContainers = {...state.sortContainers, ...action.payload};
    },
    setSortImages: (state, action) => {
      state.sortImages = {...state.sortImages, ...action.payload};
    },
    setPurchaseOrder: (state, action) => {
      state.purchaseOrder = {...state.purchaseOrder, ...action.payload};
    },
    resetInitialState: (state, action) => {
      state.sortForm = initialState.sortForm;
      state.sortContainers = initialState.sortContainers;
      state.sortImages = initialState.sortImages;
      state.purchaseOrder = initialState.purchaseOrder;
    },
  },
});

export const sortFullDetailsActions = sortFullDetailsSlice.actions;

export default sortFullDetailsSlice.reducer;
