import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  inShippingDocsPending: {data: [], pending: true},
  inShippingDocsRecent: {data: [], pending: true},
  addMaterialFormsPending: {data: [], pending: true},
  addMaterialFormsRecent: {data: [], pending: true},
  processFormsPending: {data: [], pending: true},
  processFormsRecent: {data: [], pending: true},
  outboundFormsPending: {data: [], pending: true},
  outboundFormsRecent: {data: [], pending: true},
  touched: false,
};

const accountingSlice = createSlice({
  name: 'accounting',
  initialState,
  reducers: {
    setInShippingDocsPending: (state, action) => {
      state.inShippingDocsPending = action.payload;
    },
    setInShippingDocsRecent: (state, action) => {
      state.inShippingDocsRecent = action.payload;
    },
    setAddMaterialFormsPending: (state, action) => {
      state.addMaterialFormsPending = action.payload;
    },
    setAddMaterialFormsRecent: (state, action) => {
      state.addMaterialFormsRecent = action.payload;
    },
    setProcessFormsPending: (state, action) => {
      state.processFormsPending = action.payload;
    },
    setProcessFormsRecent: (state, action) => {
      state.processFormsRecent = action.payload;
    },
    setOutboundFormsPending: (state, action) => {
      state.outboundFormsPending = action.payload;
    },
    setOutboundFormsRecent: (state, action) => {
      state.outboundFormsRecent = action.payload;
    },
    setTouched: (state, action) => {
      state.touched = action.payload;
    },
    forgetState: (state, action) => {
      state.inShippingDocsPending = initialState.inShippingDocsPending;
      state.inShippingDocsRecent = initialState.inShippingDocsRecent;
      state.addMaterialFormsPending = initialState.addMaterialFormsPending;
      state.addMaterialFormsRecent = initialState.addMaterialFormsRecent;
      state.processFormsPending = initialState.processFormsPending;
      state.processFormsRecent = initialState.processFormsRecent;
      state.outboundFormsPending = initialState.outboundFormsPending;
      state.outboundFormsRecent = initialState.outboundFormsRecent;
      state.touched = initialState.touched;
    },
  },
});

export const accountingActions = accountingSlice.actions;

export default accountingSlice.reducer;
