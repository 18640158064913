/* eslint-disable max-len */
import React from 'react';
import {SvgIcon} from '@mui/material';

const Invoice = (props) => (
  <SvgIcon viewBox='0 0 31.999998 32' {...props}>
    <g id="layer1" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="4.1" transform="translate(36 -244)">
      <path id="path9077" d="m-31 246c-1.645002 0-3 1.35499-3 3v22c0 1.64501 1.354998 3 3 3h16c1.310861 0 2.434253-.86101 2.837891-2.04297-.275362.02887-.555098.04297-.837891.04297-4.406426 0-8-3.59357-8-8s3.593574-8 8-8c.338295 0 .672094.0234 1 .0645v-7.0645c0-1.64501-1.354998-3-3-3zm3 4h10c.552284 0 1 .44772 1 1s-.447716 1-1 1h-10c-.552284 0-1-.44772-1-1s.447716-1 1-1zm0 3h10c.552284 0 1 .44772 1 1s-.447716 1-1 1h-10c-.552284 0-1-.44772-1-1s.447716-1 1-1zm0 3h6c.552284 0 1 .44772 1 1s-.447716 1-1 1h-6c-.552284 0-1-.44772-1-1s.447716-1 1-1zm1.5 4c.552285 0 1.000003.44771 1 1v.0195c.502544.2292.917313.62212 1.177734 1.10156.264412.48588.08413 1.09412-.402343 1.35743-.485421.26308-1.092197.083-1.355469-.40235-.100064-.18419-.251162-.27539-.419922-.27539-.251936 0-.5.22263-.5.59961s.248048.59961.5.59961c1.404905 0 2.5 1.20931 2.5 2.59961 0 1.03567-.609262 1.96918-1.5 2.37891v.02151c.000003.55229-.447715 1-1 1s-1.000003-.44771-1-1v-.0195c-.498977-.22815-.911363-.61855-1.171875-1.09375-.265637-.48382-.08905-1.09136.394531-1.35742.484782-.26666 1.09396-.089 1.359375.39648.100267.18294.249881.27344.417969.27344.251986 0 .5-.2226.5-.59961 0-.37704-.24803-.59964-.5-.59964-1.404883 0-2.5-1.20929-2.5-2.59961 0-1.03596.608866-1.97131 1.5-2.38086v-.01953c-.000003-.55229.447715-1 1-1z" />
      <path id="path1976" d="m-13 258c-3.301862 0-6 2.69814-6 6s2.698138 6 6 6c3.3018617 0 6-2.69814 6-6a1 1 0 0 0 -1-1 1 1 0 0 0 -1 1c0 2.22098-1.779019 4-4 4s-4-1.77902-4-4 1.779019-4 4-4c.573395 0 1.113274.11919 1.603516.33398a1 1 0 0 0 1.316406-.51367 1 1 0 0 0 -.513672-1.31836c-.737149-.32298-1.553814-.50195-2.40625-.50195z" />
      <path id="path2031" d="m-7 258a1 1 0 0 0 -.7070312.29297l-5.2929688 5.29297-1.292969-1.29297a1 1 0 0 0 -1.414062 0 1 1 0 0 0 0 1.41406l2 2a1.0001 1.0001 0 0 0 1.414062 0l6.0000003-6a1 1 0 0 0 0-1.41406 1 1 0 0 0 -.7070313-.29297z" />
    </g>
  </SvgIcon>
);

export default Invoice;
