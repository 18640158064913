/*
 * Function is used to sort Line Items in a Shipping Document. The localeCompare string methord is used
 * to ensure proper sorting of strings that include numbers (i.e. "1", "1a", "2", "2b", "10") must sort
 * natually as numbers instead of as javascript strings (i.e. "1", "1a", "10", "2", "2b").This localeLineCompare
 * function is used in multiple places to detemine the sequential Piece No. for containers, so its been
 * isolated here to a single location to ensure consistency.
 *
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare
*/
const localeLineCompare = (lineA, lineB) => {
  return lineA.localeCompare(lineB, 'en', {numeric: true});
};

export default localeLineCompare;
