/* eslint-disable max-len */
import React from 'react';
import {SvgIcon} from '@mui/material';

const AdminEdit = (props) => (
  <SvgIcon viewBox='0 0 512 512' {...props}>
    <g>
      <path d="m191.942 258.769v24.934h25.669l12.209-12.209-25.301-25.302z" />
      <path d="m225.374 188.333h88.829v35.782h-88.829z" transform="matrix(.707 -.707 .707 .707 -66.803 251.171)" />
      <path d="m391.982 347.283v-268.241h-271.964v268.241l135.982 75.231zm-230.04-33.58v-67.36l126.602-126.602 67.728 67.729-126.234 126.233z" />
      <path d="m40.976 0v393.039l215.024 118.961 215.023-118.961v-393.039zm381.006 364.971-165.982 91.828-165.982-91.828v-315.929h331.964z" />
    </g>
  </SvgIcon>
);

export default AdminEdit;
