import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Auth, Firestore} from 'firebaseConfig';
import {processesActions} from 'state/admin/slices/processes';
const snapOptions = {includeMetadataChanges: true};

const useAdminProcesses = () => {
  const {all, active, pending, touched} = useSelector(state => state.admin.processes);
  const dispatch = useDispatch();

  useEffect(() => {
    // Only run remainder of code if hook untouched; Prevents duplicate invocations;
    if (touched) return;
    dispatch(processesActions.setTouched(true));

    let unsubscribe = null;
    // Make sure user is authenticated before invoking listener
    Auth.onAuthStateChanged(user => {
      // USER NOT AUTHENTICATED
      // When user is no longer authenticate, clear redux state and unsubscribe to listener
      if (!user) {
        dispatch(processesActions.forgetState());
        if (unsubscribe) unsubscribe();
      }

      // USER IS AUTHENTICATED
      // When user is authenticated, create snapshot listener and add data results to redux state
      const query = Firestore.collection('Admin-Processes');
      const snapData = snap => {
        const {fromCache, hasPendingWrites} = snap?.metadata || {};
        if (fromCache || hasPendingWrites) return;

        const all = [];
        const active = [];

        snap.forEach(doc => {
          const docData = doc.data();
          const process = {
            ...docData,
            'DocId': doc.id,
            '@Admin-Materials:Intakes': {
              DocIds: docData?.['@Admin-Materials:Intakes']?.DocIds || [],
              List: !docData?.['@Admin-Materials:Intakes']?.List ? [] : docData?.['@Admin-Materials:Intakes']?.List.map(mapIntake => {
                const EnteredOn = mapIntake.EnteredOn.toMillis();
                return {...mapIntake, EnteredOn};
              }),
            },
            '@Admin-Materials:Yields': {
              DocIds: docData?.['@Admin-Materials:Yields']?.DocIds || [],
              List: !docData?.['@Admin-Materials:Yields']?.List ? [] : docData?.['@Admin-Materials:Yields']?.List.map(mapYield => {
                const EnteredOn = mapYield.EnteredOn.toMillis();
                return {...mapYield, EnteredOn};
              }),
            },
            'System': {
              ...docData.System,
              EnteredOn: docData?.System?.EnteredOn?.toMillis ? docData.System.EnteredOn.toMillis() : null,
            },
          };
          all.push(process);
          if (docData.Active) active.push(process);
        });

        dispatch(processesActions.setAll(all));
        dispatch(processesActions.setActive(active));
        dispatch(processesActions.setPending(false));
      };
      const snapError = error => {
        if (error.message !== 'Missing or insufficient permissions.') alert(error.message);
        console.log('useAdminProcesses Error: ', error.message, {error});
      };
      unsubscribe = Firestore.onSnapshot(query, snapOptions, snapData, snapError);
    });
  }, [dispatch, touched]);

  return {all, active, pending, touched};
};

export default useAdminProcesses;
