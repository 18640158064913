import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  inbound: {data: null, pending: true},
  currentProfile: {data: null, pending: true},
  files: {data: null, pending: true},
  materialTypes: {data: null, pending: true},
  notifications: [],
  pieces: {data: null, linesByShippingDoc: null, piecesByShippingDoc: null, pending: true},
  purchaseOrders: [],
  profileMaterials: {data: null, pending: true},
  selectedNotice: null,
  selectedShippingDoc: null,
  shipmentDocs: {data: null, map: {}, pending: true},
  weightTickets: [],
  inboundView: {
    WeightTickets: true,
    ShippingDocuments: true,
    Notifications: true,
    InboundFiles: true,
  },
};

const inboundDetailsSlice = createSlice({
  name: 'inboundDetails',
  initialState,
  reducers: {
    setInbound: (state, action) => {
      state.inbound = {...state.inbound, ...action.payload};
    },
    setCurrentProfile: (state, action) => {
      state.currentProfile = {...state.currentProfile, ...action.payload};
    },
    setFiles: (state, action) => {
      state.files = {...state.files, ...action.payload};
    },
    setPieces: (state, action) => {
      state.pieces = {...state.pieces, ...action.payload};
    },
    setMaterialTypes: (state, action) => {
      state.materialTypes = {...state.materialTypes, ...action.payload};
    },
    setNotifications: (state, action) => {
      state.notifications = [...action.payload];
    },
    setPurchaseOrders: (state, action) => {
      state.purchaseOrders = [...action.payload];
    },
    setProfileMaterials: (state, action) => {
      state.profileMaterials = {...state.profileMaterials, ...action.payload};
    },
    setSelectedNotice: (state, action) => {
      state.selectedNotice = action.payload;
    },
    setSelectedShippingDoc: (state, action) => {
      state.selectedShippingDoc = action.payload;
    },
    setShipmentDocs: (state, action) => {
      state.shipmentDocs = {...state.shipmentDocs, ...action.payload};
    },
    setWeightTickets: (state, action) => {
      state.weightTickets = [...action.payload];
    },
    setInboundView: (state, action) => {
      const {payload} = action;
      const {inboundView} = state;
      if (typeof payload?.WeightTickets === 'boolean') inboundView.WeightTickets = payload.WeightTickets;
      if (typeof payload?.ShippingDocuments === 'boolean') inboundView.ShippingDocuments = payload.ShippingDocuments;
      if (typeof payload?.Notifications === 'boolean') inboundView.Notifications = payload.Notifications;
      if (typeof payload?.InboundFiles === 'boolean') inboundView.InboundFiles = payload.InboundFiles;
    },
    resetInitialState: (state, action) => {
      state.inbound = initialState.inbound;
      state.currentProfile = initialState.currentProfile;
      state.files = initialState.files;
      state.materialTypes = initialState.materialTypes;
      state.notifications = initialState.notifications;
      state.pieces = initialState.pieces;
      state.purchaseOrders = initialState.purchaseOrders;
      state.profileMaterials = initialState.profileMaterials;
      state.selectedNotice = initialState.selectedNotice;
      state.shipmentDocs = initialState.shipmentDocs;
      state.weightTickets = initialState.weightTickets;
    },
  },
});

export const inboundDetailsActions = inboundDetailsSlice.actions;

export default inboundDetailsSlice.reducer;
