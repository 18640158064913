/* eslint-disable max-len */
import React from 'react';
import {SvgIcon} from '@mui/material';

const SortForm = (props) => (
  <SvgIcon viewBox='0 0 60 60' {...props}>
    <path d="m45.363 57.369a.7.7 0 0 0 .7.631h9.878a.7.7 0 0 0 .7-.631l.932-9.369h-13.146z" />
    <path d="m17 22.929 3.349-5.012a1.01 1.01 0 0 1 1.388-.276l.874.586-.88-4.212-4.222.82.905.575a1.014 1.014 0 0 1 .277 1.388l-2.3 3.434a2.312 2.312 0 0 0 -.391 1.294 1.342 1.342 0 0 0 1 1.403z" />
    <path d="m28.086 3.173a3.636 3.636 0 0 1 .8-1.1 3.071 3.071 0 0 0 -3.313 1.841l-2.573 4.765 1.714.975z" />
    <path d="m16.1 24.719a3 3 0 0 0 2.9 2.288h6v-2h-7.517a3.641 3.641 0 0 1 -1.383-.288z" />
    <path d="m33.185 10.194-.89.571 4.216.869.937-4.184c0-.015-.889.591-.889.591a1.011 1.011 0 0 1 -1.386-.3l-2.3-3.59a2.373 2.373 0 0 0 -1.043-.885 1.332 1.332 0 0 0 -1.672.356l3.329 5.189a1.01 1.01 0 0 1 -.302 1.383z" />
    <path d="m35 27.012h4.31a2.379 2.379 0 0 0 1.294-.392l.009-.006a1.344 1.344 0 0 0 .6-1.6h-6.213a1 1 0 0 1 -1-1v-1.014l-3 3.039 3.016 2.961-.016-.988a1 1 0 0 1 1-1z" />
    <path d="m24.363 57.369a.7.7 0 0 0 .7.631h9.878a.7.7 0 0 0 .7-.631l.932-9.369h-13.146z" />
    <path d="m57.819 43.23a.7.7 0 0 0 -.519-.23h-12.6a.7.7 0 0 0 -.7.77l.227 2.23h13.546l.227-2.23a.7.7 0 0 0 -.181-.54z" />
    <path d="m36.3 43h-12.6a.7.7 0 0 0 -.7.77l.227 2.23h13.546l.227-2.23a.7.7 0 0 0 -.7-.77z" />
    <path d="m3.363 57.369a.7.7 0 0 0 .7.631h9.878a.7.7 0 0 0 .7-.631l.932-9.369h-13.146z" />
    <path d="m15.3 43h-12.6a.7.7 0 0 0 -.7.77l.227 2.23h13.546l.227-2.23a.7.7 0 0 0 -.7-.77z" />
    <path d="m42.673 23.453a3.606 3.606 0 0 1 .531 1.313 3.014 3.014 0 0 0 .294-3.695l-3.337-4.988-1.666 1.117 4.177 6.251z" />
    <path d="m9 39a1 1 0 0 0 1-1v-2h19v2a1 1 0 0 0 2 0v-2h19v2a1 1 0 0 0 2 0v-2a2 2 0 0 0 -2-2h-19v-2a1 1 0 0 0 -2 0v2h-19a2 2 0 0 0 -2 2v2a1 1 0 0 0 1 1z" />
  </SvgIcon>
);

export default SortForm;
