/* eslint-disable max-len */
import React from 'react';
import {SvgIcon} from '@mui/material';

const Consolidation = (props) => (
  <SvgIcon viewBox='0 0 32 32' {...props}>
    <g id="_x30_4">
      <path d="m17.2929688 21.7070313c.1953125.1953125.4511718.2929687.7070312.2929687s.5117188-.0976563.7070313-.2929688c.390625-.390625.390625-1.0234375 0-1.4140625l-2-2c-.390625-.390625-1.0234375-.390625-1.4140625 0l-2 2c-.390625.390625-.390625 1.0234375 0 1.4140625s1.0234375.390625 1.4140625 0l.2929687-.2929687v2.5859375c0 .5512695-.4482422 1-1 1-.5527344 0-1 .4477539-1 1s.4472656 1 1 1c.7711182 0 1.4680176-.3005371 2-.7802734.5319824.4797363 1.2288818.7802734 2 .7802734.5527344 0 1-.4477539 1-1s-.4472656-1-1-1c-.5517578 0-1-.4487305-1-1v-2.5859375z" />
      <path d="m7.4472656 19.1054688c-.28125-.140625-.6132813-.140625-.8945313 0 0 0-4.0620117 2.0438843-4.0940552 2.0648193l4.5413209 2.2706299 4.5413208-2.2706299c-.0320435-.0209351-4.0940552-2.0648193-4.0940552-2.0648193z" />
      <path d="m2 27c0 .3789063.2138672.7250977.5527344.8945313l3.9472656 2.1054687v-5.690918l-4.5-2.25z" />
      <path d="m7.5 30 3.9472656-2.1054688c.3388672-.1694335.5527344-.5156249.5527344-.8945312v-4.940918l-4.5 2.25z" />
      <path d="m25.4472656 19.1054688c-.28125-.140625-.6132813-.140625-.8945313 0 0 0-4.0620117 2.0438843-4.0940552 2.0648193l4.5413209 2.2706299 4.5413208-2.2706299c-.0320435-.0209351-4.0940552-2.0648193-4.0940552-2.0648193z" />
      <path d="m20 27c0 .3789063.2138672.7250977.5527344.8945313l3.9472656 2.1054687v-5.690918l-4.5-2.25z" />
      <path d="m25.5 24.309082v5.690918l3.9472656-2.1054688c.3388672-.1694335.5527344-.5156249.5527344-.8945312v-4.940918z" />
      <path d="m16.5 17 5.8935547-3.0810547c.3681641-.1572265.6064453-.519043.6064453-.9189453v-6.9997559c0-.0026855-.0011597-.0050659-.0011597-.0077515l-6.4988403 3.3328248z" />
      <path d="m22.473999 5.1309814c-.0280151-.0153198-6.0804443-3.0496826-6.0804443-3.0496826-.25-.1083984-.5371094-.1083984-.7871094 0 0 0-6.0524292 3.0343628-6.0804443 3.0496826l6.473999 3.3218995z" />
      <path d="m9.6064453 13.9189453 5.8935547 3.0810547v-7.6746826l-6.4988403-3.3328247c0 .0026855-.0011597.0050659-.0011597.0077514v6.9997559c0 .3999023.2382813.7617188.6064453.9189453z" />
    </g>
  </SvgIcon>
);

export default Consolidation;
