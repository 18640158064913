import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  consolidation: {data: [], pending: true},
  process: {data: [], pending: true},
  kbiMaterial: {data: [], pending: true},
  sortFull: {data: [], pending: true},
  sortQuick: {data: [], pending: true},
  touched: false,
};

const productionSlice = createSlice({
  name: 'production',
  initialState,
  reducers: {
    setConsolidation: (state, action) => {
      state.consolidation = action.payload;
    },
    setProcess: (state, action) => {
      state.process = action.payload;
    },
    setSortFull: (state, action) => {
      state.sortFull = action.payload;
    },
    setSortQuick: (state, action) => {
      state.sortQuick = action.payload;
    },
    setKbiMaterial: (state, action) => {
      state.kbiMaterial = action.payload;
    },
    setTouched: (state, action) => {
      state.touched = action.payload;
    },
    forgetState: (state, action) => {
      state.consolidation = initialState.consolidation;
      state.kbiMaterial = initialState.kbiMaterial;
      state.process = initialState.process;
      state.sortFull = initialState.sortFull;
      state.sortQuick = initialState.sortQuick;
      state.touched = initialState.touched;
    },
  },
});

export const productionActions = productionSlice.actions;

export default productionSlice.reducer;
