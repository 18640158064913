/* eslint-disable max-len */
import React from 'react';
import {SvgIcon} from '@mui/material';

const Inventory = (props) => (
  <SvgIcon viewBox='0 0 512 512' {...props}>
    <g>
      <path d="m.058 464.42v32.58c0 8.28 6.719 15 14.997 15h59.99c8.279 0 14.997-6.72 14.997-15v-30c-74.205-.792-77.51 1.863-89.984-2.58z" />
      <path d="m211.022 497c0 8.28 6.719 15 14.997 15h59.99c8.279 0 14.997-6.72 14.997-15v-30h-89.985v30z" />
      <path d="m421.986 467v30c0 8.28 6.719 15 14.997 15h59.99c8.279 0 14.997-6.72 14.997-15v-32.58c-12.505 4.454-16.595 1.797-89.984 2.58z" />
      <path d="m511.11 372c-19.672 0-490.499 0-510.192 0-1.575 4.415-.584 5.416-.86 50 0 8.28 6.719 15 14.997 15h481.917c8.279 0 14.997-6.72 14.997-15-.288-46.671.655-45.756-.859-50z" />
      <path d="m121.037 0h29.995v30h-29.995z" />
      <path d="m226.019 0h-44.992v45c0 8.284-6.715 15-14.997 15h-59.99c-8.283 0-14.997-6.716-14.997-15v-45h-44.993c-8.283 0-14.997 6.716-14.997 15v130c0 8.284 6.715 15 14.997 15h179.969c8.283 0 14.997-6.716 14.997-15v-130c.001-8.284-6.714-15-14.997-15z" />
      <path d="m360.996 0h29.995v30h-29.995z" />
      <path d="m480.976 15c0-8.284-6.715-15-14.997-15h-44.992v45c0 8.284-6.715 15-14.997 15h-59.99c-8.283 0-14.997-6.716-14.997-15v-45h-44.992c-8.283 0-14.997 6.716-14.997 15v130c0 8.284 6.715 15 14.997 15h179.969c8.283 0 14.997-6.716 14.997-15v-130z" />
      <path d="m121.037 190h29.995v30h-29.995z" />
      <path d="m226.019 190h-44.992v45c0 8.284-6.715 15-14.997 15h-59.99c-8.283 0-14.997-6.716-14.997-15v-45h-44.993c-8.283 0-14.997 6.716-14.997 15v122c0 8.284 6.715 15 14.997 15h179.969c8.283 0 14.997-6.716 14.997-15v-122c.001-8.284-6.714-15-14.997-15z" />
      <path d="m360.996 190h29.995v30h-29.995z" />
      <path d="m465.978 190h-44.992v45c0 8.284-6.715 15-14.997 15h-59.99c-8.283 0-14.997-6.716-14.997-15v-45h-44.992c-8.283 0-14.997 6.716-14.997 15v122c0 8.284 6.715 15 14.997 15h179.969c8.283 0 14.997-6.716 14.997-15v-122c0-8.284-6.715-15-14.998-15z" />
    </g>
  </SvgIcon>
);

export default Inventory;
