import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  appUsers: {data: null, touched: false},
  currentUser: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAppUsers: (state, action) => {
      state.appUsers = {...state.appUsers, ...action.payload};
    },
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
    forgetState: (state, action) => {
      state.appUsers = initialState.appUsers;
      state.currentUser = initialState.currentUser;
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
