/* eslint-disable max-len */
import React from 'react';
import {SvgIcon} from '@mui/material';

const Ledger = (props) => (
  <SvgIcon viewBox='0 0 612.127 612.127' {...props}>
    <g>
      <path d="m601.196 131.17h-10.931v393.51c0 18.111-14.682 32.793-32.792 32.793h-502.819c-18.111 0-32.793-14.682-32.793-32.793v-393.51h-10.93c-6.037 0-10.931 4.894-10.931 10.931v426.303c0 6.037 4.894 10.931 10.931 10.931h240.479c6.037 0 10.931 4.894 10.931 10.931v10.931c0 6.037 4.894 10.931 10.931 10.931h65.585c6.037 0 10.931-4.894 10.931-10.931v-10.931c0-6.037 4.894-10.931 10.931-10.931h240.479c6.037 0 10.931-4.894 10.931-10.931v-426.303c-.002-6.037-4.896-10.931-10.933-10.931z" />
      <path d="m502.819 327.248c-.007 9.512-2.489 18.858-7.203 27.119l-28.475 49.823c-5.995 10.48-19.352 14.116-29.832 8.12-3.382-1.935-6.185-4.738-8.12-8.12l-28.475-49.834c-4.716-8.257-7.198-17.6-7.203-27.108v-261.663h-32.793c-24.148 0-43.723 19.576-43.723 43.723v388.482c11.988-10.831 27.567-16.829 43.723-16.834h196.755c6.037 0 10.931-4.894 10.931-10.931v-393.509c0-6.037-4.894-10.931-10.931-10.931h-54.654z" />
      <path d="m480.957 10.931c0-6.037-4.894-10.931-10.931-10.931h-43.723c-6.037 0-10.931 4.894-10.931 10.931v21.862h65.585z" />
      <path d="m54.654 535.611h238.926c-4.969-19.246-22.294-32.718-42.171-32.793h-196.755c-3.732-.036-7.43-.717-10.931-2.011v23.873c0 6.037 4.894 10.931 10.931 10.931z" />
      <path d="m360.718 502.819c-19.877.074-37.202 13.547-42.171 32.793h238.926c6.037 0 10.931-4.894 10.931-10.931v-23.873c-3.501 1.295-7.199 1.975-10.931 2.011z" />
      <path d="m415.372 54.654h65.585v32.793h-65.585z" />
      <path d="m415.372 327.248c0 .23.087.448.087.678h21.774v-218.618h-21.862v217.94z" />
      <path d="m480.957 327.248v-217.94h-21.862v218.617h21.774c.001-.229.088-.448.088-.677z" />
      <path d="m448.165 393.335 24.878-43.548h-49.757z" />
      <path d="m54.654 480.957h196.755c16.156.004 31.736 6.003 43.723 16.834v-388.483c0-24.148-19.576-43.723-43.723-43.723h-196.755c-6.037 0-10.931 4.894-10.931 10.931v393.51c0 6.037 4.894 10.931 10.931 10.931zm109.309-262.34c-24.099.03-43.66-19.482-43.69-43.582-.025-19.931 13.458-37.345 32.759-42.313v-12.483c0-6.037 4.894-10.931 10.931-10.931s10.931 4.894 10.931 10.931v12.483c19.246 4.969 32.718 22.294 32.793 42.171 0 6.037-4.894 10.931-10.931 10.931s-10.931-4.894-10.931-10.931c0-12.074-9.788-21.862-21.862-21.862s-21.862 9.788-21.862 21.862 9.788 21.862 21.862 21.862c24.099-.03 43.66 19.482 43.69 43.582.025 19.931-13.458 37.345-32.759 42.313v12.483c0 6.037-4.894 10.931-10.931 10.931s-10.931-4.894-10.931-10.931v-12.483c-19.246-4.969-32.718-22.294-32.793-42.171 0-6.037 4.894-10.931 10.931-10.931s10.931 4.894 10.931 10.931c0 12.074 9.788 21.862 21.862 21.862s21.862-9.788 21.862-21.862-9.788-21.862-21.862-21.862zm-76.516 120.239h163.963c6.037 0 10.931 4.894 10.931 10.931s-4.894 10.931-10.931 10.931h-163.963c-6.037 0-10.931-4.894-10.931-10.931s4.894-10.931 10.931-10.931zm0 54.654h163.963c6.037 0 10.931 4.894 10.931 10.931s-4.894 10.931-10.931 10.931h-163.963c-6.037 0-10.931-4.894-10.931-10.931s4.894-10.931 10.931-10.931z" />
    </g>
  </SvgIcon>
);

export default Ledger;
