import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  location: null,
  unitNames: null,
  seriesData: null,
  pending: true,
  touched: false,
};

const dashUnitCapacitySlice = createSlice({
  name: 'dashUnitCapacity',
  initialState,
  reducers: {
    setLocation: (state, action) => {
      state.location = action.payload;
    },
    setUnitNames: (state, action) => {
      state.unitNames = action.payload;
    },
    setSeriesData: (state, action) => {
      state.seriesData = action.payload;
    },
    setPending: (state, action) => {
      state.pending = action.payload;
    },
    setTouched: (state, action) => {
      state.touched = action.payload;
    },
    forgetState: (state, action) => {
      state.location = initialState.location;
      state.unitNames = initialState.unitNames;
      state.setSeriesData = initialState.setSeriesData;
      state.pending = initialState.pending;
      state.touched = initialState.touched;
    },
  },
});

export const dashUnitCapacityActions = dashUnitCapacitySlice.actions;

export default dashUnitCapacitySlice.reducer;
