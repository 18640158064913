/* eslint-disable max-len */
import React from 'react';
import {SvgIcon} from '@mui/material';

const ProcessForm = (props) => (
  <SvgIcon viewBox='0 0 512 512' {...props}>
    <g>
      <g>
        <path d="m512 364.742c0-38.392-31.234-69.625-69.625-69.625h-85.685v-38.279c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v38.279h-78.19v-109.25h78.19v38.29c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-42.687l28.868-28.924c4.776-4.38 2.776-12.798-5.309-12.798h-93.187c-1.992 0-3.901.792-5.309 2.202l-25.753 25.805-25.753-25.804c-1.407-1.41-3.317-2.202-5.309-2.202h-93.187c-8.084 0-10.084 7.5-5.309 12.798l28.871 28.927v113.643h-85.688c-38.391 0-69.625 31.234-69.625 69.625 0 35.86 27.25 65.474 62.13 69.224v23.971c0 4.143 3.358 7.5 7.5 7.5h232.15c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-224.65v-16.07h102.05c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-109.555c-30.12 0-54.625-24.505-54.625-54.625s24.505-54.625 54.625-54.625h372.75c30.12 0 54.625 24.505 54.625 54.625s-24.505 54.625-54.625 54.625h-230.505c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h223v16.07h-100.4c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h107.9c4.142 0 7.5-3.357 7.5-7.5v-23.971c34.88-3.75 62.13-33.364 62.13-69.224zm-221.827-209.993h71.995l-16.087 16.118h-71.994zm-68.346 0 16.091 16.123h-71.994l-16.092-16.123zm-51.514 31.123h78.187v109.245h-78.187z" />
        <path d="m442.374 403.308c21.263 0 38.562-17.299 38.562-38.562 0-21.264-17.299-38.562-38.562-38.562h-372.748c-21.263 0-38.562 17.299-38.562 38.562s17.299 38.562 38.562 38.562zm-272.061-38.562c0-12.992 10.57-23.562 23.562-23.562s23.562 10.57 23.562 23.562-10.57 23.562-23.562 23.562-23.562-10.57-23.562-23.562zm-6.944 23.562h-63.236c10.622-13.72 10.621-33.404 0-47.124h63.236c-10.621 13.72-10.621 33.403 0 47.124zm61.013-47.124h63.235c-10.621 13.72-10.622 33.404 0 47.124h-63.235c10.622-13.721 10.622-33.404 0-47.124zm70.18 23.562c0-12.992 10.57-23.562 23.562-23.562s23.562 10.57 23.562 23.562-10.57 23.562-23.562 23.562-23.562-10.57-23.562-23.562zm54.069-23.562h63.236c-10.621 13.72-10.622 33.404 0 47.124h-63.236c10.621-13.721 10.621-33.404 0-47.124zm117.305 23.562c0 12.992-10.57 23.562-23.562 23.562s-23.562-10.569-23.562-23.562 10.57-23.562 23.562-23.562 23.562 10.57 23.562 23.562zm-419.872 0c0-12.992 10.57-23.562 23.562-23.562s23.562 10.57 23.562 23.562-10.57 23.562-23.562 23.562-23.562-10.57-23.562-23.562z" />
        <path d="m193.875 247.997h15.531c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-15.531c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5z" />
        <path d="m209.407 263.998h-15.531c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h15.531c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5z" />
        <path d="m250.697 121.49c2.892 2.885 7.715 2.885 10.606 0l15.531-15.53c2.929-2.929 2.923-7.672 0-10.606-4.668-4.687-10.302-1.592-13.335 2.727v-44.019c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v44.018c-3.033-4.319-8.667-7.081-13.335-2.727-3.029 2.825-2.928 7.678 0 10.606z" />
        <path d="m312.821 105.96c2.892 2.885 7.715 2.885 10.606 0l15.531-15.531c2.929-2.93 3.184-7.957 0-10.607-4.792-3.988-10.302-1.591-13.334 2.728v-28.488c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v28.487c-3.033-4.319-9.291-7.049-13.334-2.728-2.831 3.025-2.929 7.678 0 10.607z" />
        <path d="m188.572 105.96c2.892 2.885 7.714 2.885 10.606 0l15.531-15.531c2.929-2.93 3.014-7.766 0-10.607-3.877-3.655-10.302-1.591-13.334 2.728v-28.488c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v28.487c-3.033-4.319-8.562-7.049-13.334-2.728-3.071 2.781-2.929 7.678 0 10.607z" />
      </g>
      <g>
        <path d="m69.627 372.246c-4.142 0-7.5-3.358-7.5-7.5 0-4.142 3.358-7.5 7.5-7.5 4.142 0 7.5 3.358 7.5 7.5 0 4.142-3.358 7.5-7.5 7.5z" />
        <path d="m193.876 372.246c-4.142 0-7.5-3.358-7.5-7.5 0-4.142 3.358-7.5 7.5-7.5 4.142 0 7.5 3.358 7.5 7.5 0 4.142-3.358 7.5-7.5 7.5z" />
        <path d="m318.191 372.246c-4.142 0-7.5-3.358-7.5-7.5 0-4.142 3.358-7.5 7.5-7.5 4.142 0 7.5 3.358 7.5 7.5 0 4.142-3.358 7.5-7.5 7.5z" />
        <path d="m442.373 372.246c-4.142 0-7.5-3.358-7.5-7.5 0-4.142 3.358-7.5 7.5-7.5 4.142 0 7.5 3.358 7.5 7.5 0 4.142-3.357 7.5-7.5 7.5z" />
      </g>
    </g>
  </SvgIcon>
);

export default ProcessForm;
