/* eslint-disable max-len */
import React from 'react';
import {SvgIcon} from '@mui/material';

const Production = (props) => (
  <SvgIcon viewBox='0 0 32 32' {...props}>
    <g>
      <path d="m24.7515869 10.0253296c0-.0822144-.0100098-.164978-.0308838-.246521-.6530151-2.5646973-2.4027099-4.65979-4.7207031-5.8117065v2.0328979c0 .5522461-.4472656 1-1 1s-1-.4477539-1-1v-2.7192383-.2807617c0-.5523071-.4476929-1-1-1h-2c-.5523071 0-1 .4476929-1 1v.2806396 2.7193604c0 .5522461-.4477539 1-1 1s-1-.4477539-1-1v-2.0328979c-2.3184814 1.1518554-4.0681763 3.2470092-4.7211914 5.8117065-.020752.081604-.0281372.1641235-.024292.2459106-.7109375.1177369-1.2545166.7309571-1.2545166 1.4752808 0 .8284302.6715698 1.5 1.5 1.5h17c.8284302 0 1.5-.6715698 1.5-1.5 0-.7422485-.5404053-1.3544922-1.2484131-1.4746704z" />
      <path d="m23 15h-14v1c0 1.9090576.7699585 3.6403198 2.0134277 4.9044189-.0031738.0322266-.0134277.0627442-.0134277.0955811v1.6835327l4.9750977 2.2109985 5.0249023-2.5126342v-1.381897c0-.0328979-.0102539-.0634766-.0134277-.0957642 1.2434082-1.2640991 2.0134277-2.9953003 2.0134277-4.9042358z" />
      <g>
        <path d="m10 30h12v-5.8818359l-5.5527344 2.7763672c-.140625.0703125-.2939453.1054687-.4472656.1054687-.1381836 0-.2768555-.0288086-.40625-.0864258l-5.59375-2.486084z" />
        <path d="m8 24.1120605c-2.3813477.7294312-4.4486694 2.2577515-5.8320313 4.333252-.2045898.3066406-.2236328.7011719-.0498047 1.0263672.1743165.3251953.5131837.5283203.881836.5283203h5z" />
        <path d="m29.8320313 28.4453125c-1.3832398-2.0753784-3.4504395-3.6036377-5.8320313-4.3331299v5.8878174h5c.3691406 0 .7080078-.203125.8818359-.5283203s.1542969-.7197266-.0498046-1.0263672z" />
      </g>
    </g>
  </SvgIcon>
);

export default Production;
