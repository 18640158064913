import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Auth, Firestore} from 'firebaseConfig';
import {accountActions} from 'state/accounts/slice';
const snapOptions = {includeMetadataChanges: true};

const useAccountsIndex = () => {
  const {index, pending, touched} = useSelector(state => state.accounts);
  const dispatch = useDispatch();

  useEffect(() => {
    // Only run remainder of code if hook untouched; Prevents duplicate invocations;
    if (touched) return;
    dispatch(accountActions.setTouched(true));

    let unsubscribe = null;
    // Make sure user is authenticated before invoking listener
    Auth.onAuthStateChanged(user => {
      // USER NOT AUTHENTICATED
      // When user is no longer authenticate, clear redux state and unsubscribe to listener
      if (!user) {
        dispatch(accountActions.forgetState());
        if (unsubscribe) unsubscribe();
      }

      // USER IS AUTHENTICATED
      // When user is authenticated, create snapshot listener and add data results to redux state
      const query = Firestore.collection('Index-Accounts');
      const snapData = snap => {
        const {fromCache, hasPendingWrites} = snap?.metadata || {};
        if (fromCache || hasPendingWrites) return;

        const all = [];
        const inbound = [];
        const outbound = [];
        const transporters = [];

        snap.forEach(doc => {
          doc.data().Accounts.forEach(account => {
            all.push(account);
            if (account.Group.Inbound) inbound.push(account);
            if (account.Group.Outbound) outbound.push(account);
            if (account.Group.Transporter) transporters.push(account);
          });
        });

        dispatch(accountActions.setIndex({all, inbound, outbound, transporters}));
        dispatch(accountActions.setPending(false));
      };
      const snapError = error => {
        if (error.message !== 'Missing or insufficient permissions.') alert(error.message);
        console.log('useAccountsIndex Error: ', error.message, {error});
      };
      unsubscribe = Firestore.onSnapshot(query, snapOptions, snapData, snapError);
    });
  }, [dispatch, touched]);

  return {index, pending, touched};
};

export default useAccountsIndex;
