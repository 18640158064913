import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  process: {data: null, pending: true},
  facilityUnits: {data: null, pending: true},
  processMaterials: {data: null, pending: true},
  yieldMaterials: {data: null, pending: true},
  byproductMaterials: {data: null, pending: true},
};

const adminProcessDetailsSlice = createSlice({
  name: 'adminProcessDetails',
  initialState,
  reducers: {
    setProcess: (state, action) => {
      state.process = {...state.process, ...action.payload};
    },
    setFacilityUnits: (state, action) => {
      state.facilityUnits = {...state.facilityUnits, ...action.payload};
    },
    setProcessMaterials: (state, action) => {
      state.processMaterials = {...state.processMaterials, ...action.payload};
    },
    setYieldMaterials: (state, action) => {
      state.yieldMaterials = {...state.yieldMaterials, ...action.payload};
    },
    setByproductMaterials: (state, action) => {
      state.byproductMaterials = {...state.byproductMaterials, ...action.payload};
    },
    resetInitialState: (state, action) => {
      state.process = initialState.process;
      state.facilityUnits = initialState.facilityUnits;
      state.processMaterials = initialState.processMaterials;
      state.yieldMaterials = initialState.yieldMaterials;
      state.byproductMaterials = initialState.byproductMaterials;
    },
  },
});

export const adminProcessDetailsActions = adminProcessDetailsSlice.actions;

export default adminProcessDetailsSlice.reducer;
