/* eslint-disable max-len */
import React from 'react';
import {SvgIcon} from '@mui/material';

const Split = (props) => (
  <SvgIcon viewBox='0 0 24 24' {...props}>
    <g>
      <g>
        <path d="m18 23c-.6 0-1-.4-1-1v-7c0-1.7-1.3-3-3-3h-8c-.6 0-1-.4-1-1s.4-1 1-1h8c2.8 0 5 2.2 5 5v7c0 .6-.4 1-1 1z" />
      </g>
      <g>
        <path d="m6 8c-1.9 0-3.5-1.6-3.5-3.5s1.6-3.5 3.5-3.5 3.5 1.6 3.5 3.5-1.6 3.5-3.5 3.5zm0-5c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5 1.5-.7 1.5-1.5-.7-1.5-1.5-1.5z" />
      </g>
      <g>
        <path d="m6 22.5c-.6 0-1-.4-1-1v-14.5c0-.6.4-1 1-1s1 .4 1 1v14.5c0 .6-.4 1-1 1z" />
      </g>
      <g>
        <path d="m6 23c-.3 0-.5-.1-.7-.3l-2.5-2.5c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l1.8 1.8 1.8-1.8c.4-.4 1-.4 1.4 0s.4 1 0 1.4l-2.5 2.5c-.2.2-.4.3-.7.3z" />
      </g>
      <g>
        <path d="m18 23c-.3 0-.5-.1-.7-.3l-2.5-2.5c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l1.8 1.8 1.8-1.8c.4-.4 1-.4 1.4 0s.4 1 0 1.4l-2.5 2.5c-.2.2-.4.3-.7.3z" />
      </g>
    </g>
  </SvgIcon>
);

export default Split;
