import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  processForm: {data: null, pending: true},
  allowed: {
    processMaterials: null,
    processMaterialsDocIds: null,
    processYields: null,
    processYieldsDocIds: null,
    processByproducts: null,
    processByproductsDocIds: null,
    pending: true,
  },
  addedProcessMaterials: {data: null, pending: true},
  addedProcessYields: {data: null, pending: true},
  addedProcessByproducts: {data: null, pending: true},
};

const processDetailsSlice = createSlice({
  name: 'processDetails',
  initialState,
  reducers: {
    setProcessForm: (state, action) => {
      state.processForm = {...state.processForm, ...action.payload};
    },
    setAllowed: (state, action) => {
      state.allowed = {...state.allowed, ...action.payload};
    },
    setAddedProcessMaterials: (state, action) => {
      state.addedProcessMaterials = {...state.addedProcessMaterials, ...action.payload};
    },
    setAddedProcessYields: (state, action) => {
      state.addedProcessYields = {...state.addedProcessYields, ...action.payload};
    },
    setAddedProcessByproducts: (state, action) => {
      state.addedProcessByproducts = {...state.addedProcessByproducts, ...action.payload};
    },
    resetInitialState: (state, action) => {
      state.processForm = initialState.processForm;
      state.allowed = initialState.allowed;
      state.addedProcessMaterials = initialState.addedProcessMaterials;
      state.addedProcessYields = initialState.addedProcessYields;
      state.addedProcessByproducts = initialState.addedProcessByproducts;
    },
  },
});

export const processDetailsActions = processDetailsSlice.actions;

export default processDetailsSlice.reducer;
