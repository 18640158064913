import {initializeApp} from 'firebase/app';
import {initializeAppCheck, ReCaptchaV3Provider} from 'firebase/app-check';
import {getAuth, onAuthStateChanged, onIdTokenChanged} from 'firebase/auth';
import {signInWithCustomToken, signOut, updateEmail, updateProfile} from 'firebase/auth';
import {getFirestore, addDoc, collection, collectionGroup, deleteDoc, deleteField, doc, getDoc, getDocs, limit, onSnapshot, orderBy, writeBatch} from 'firebase/firestore';
import {query, runTransaction, setDoc, serverTimestamp, where, arrayUnion, arrayRemove, updateDoc} from 'firebase/firestore';
import {getFunctions, httpsCallableFromURL, connectFunctionsEmulator} from 'firebase/functions';
import {getRemoteConfig, ensureInitialized, fetchAndActivate, getValue} from 'firebase/remote-config';
import {getStorage, deleteObject, getDownloadURL, getMetadata, updateMetadata, listAll, ref, uploadBytesResumable, uploadBytes} from 'firebase/storage';

const isProductionEnv = process.env.NODE_ENV === 'production';
const FireProd = initializeApp({
  apiKey: 'AIzaSyDs10eUX0HfBaHel1gaijLzVhRkY8n9q3s',
  authDomain: 'kbi-batteries-prod.firebaseapp.com',
  projectId: 'kbi-batteries-prod',
  storageBucket: 'kbi-batteries-prod.appspot.com',
  messagingSenderId: '128170931820',
  appId: '1:128170931820:web:e03858b9631acbd1308a49',
  measurementId: 'G-EQ3ELJ7KV5',
}, 'FireProd');
const FireDev = initializeApp({
  apiKey: 'AIzaSyBvQX99IHmq61PhYuSkZ8XKhnp-K1qJQWw',
  authDomain: 'kbi-batteries-dev.firebaseapp.com',
  projectId: 'kbi-batteries-dev',
  storageBucket: 'kbi-batteries-dev.appspot.com',
  messagingSenderId: '429027128535',
  appId: '1:429027128535:web:f50962826f31667061ec92',
  measurementId: 'G-FRKY9T2HB9',
}, 'FireDev');

// Initialize Firebase App Check (Only for Production Environment)
// https://firebase.google.com/docs/app-check/web/recaptcha-provider
if (isProductionEnv) {
  initializeAppCheck(FireProd, {
    provider: new ReCaptchaV3Provider('6LfIRKEkAAAAAKOHHK7eMtJqBgrsFS2RrW4Ql1Fn'),
    isTokenAutoRefreshEnabled: true,
  });
}

// Initialize Firebase Authentication with conditional override for development environment
const AuthInit = getAuth(isProductionEnv ? FireProd : FireDev);
const Auth = {
  currentUser: () => AuthInit.currentUser,
  onAuthStateChanged: cb => onAuthStateChanged(AuthInit, cb),
  onIdTokenChanged: cb => onIdTokenChanged(AuthInit, cb),
  signInWithCustomToken: customToken => signInWithCustomToken(AuthInit, customToken),
  signOut: () => signOut(AuthInit),
  updateEmail: email => updateEmail(AuthInit.currentUser, email),
  updateProfile: profile => updateProfile(AuthInit.currentUser, profile),
};

// Initialize Firebase Firestore with conditional override for development environment
const FirestoreInit = getFirestore(isProductionEnv ? FireProd : FireDev);
const Firestore = {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection: collectionPath => collection(FirestoreInit, collectionPath),
  collectionGroup: collectionPath => collectionGroup(FirestoreInit, collectionPath),
  deleteDoc,
  deleteField,
  doc: param => {
    // When param type is a Firestore collection, passing FirestoreInit will cause system crash (Init already passed to collection);
    if (param?.type === 'collection') return doc(param);
    // Otherewise pass FirestoreInit to properly initialize doc module;
    return doc(FirestoreInit, param);
  },
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  runTransaction: transaction => runTransaction(FirestoreInit, transaction),
  setDoc,
  serverTimestamp,
  updateDoc,
  where,
  writeBatch: () => writeBatch(FirestoreInit),
};

// Initialize Firebase Functions with conditional override for development environment
const useFunctionEmulator = false;
const FunctionsInit = getFunctions((() => {
  // Always init FireProd when using production environment
  if (isProductionEnv) return FireProd;
  // Init FireProd when using development environment and Firebase Function emulator
  else if (!isProductionEnv && useFunctionEmulator) return FireProd;
  // Init FireDev when using development environment but not Firebase Function emulator
  else if (!isProductionEnv && !useFunctionEmulator) return FireDev;
})());
if (!isProductionEnv && useFunctionEmulator) {
  console.log('Firebase looking for Cloud Functions emulator. To prevent errors, ensure emulator is running or set useFunctionEmulator false.');
  connectFunctionsEmulator(FunctionsInit, 'localhost', 5001);
}
const Functions = {
  getFunctionURL: (functionName) => {
    if (isProductionEnv) return `https://us-central1-kbi-batteries-prod.cloudfunctions.net/${functionName}`;
    else return `https://us-central1-kbi-batteries-dev.cloudfunctions.net/${functionName}`;
  },
  httpsCallableFromURL: functionUrl => httpsCallableFromURL(FunctionsInit, functionUrl),
};

// Initialize RemoteConfig with conditional override for development environment
const RemoteConfigInit = getRemoteConfig(isProductionEnv ? FireProd : FireDev);
RemoteConfigInit.settings.minimumFetchIntervalMillis = isProductionEnv ? 3600000 : 15000; // One hour : 15 seconds
const RemoteConfig = {
  ensureInitialized: () => ensureInitialized(RemoteConfigInit),
  fetchAndActivate: () => fetchAndActivate(RemoteConfigInit),
  getValue: key => getValue(RemoteConfigInit, key),
};

// Initialize Firebase Storage with conditional override for development environment
const StorageInit = getStorage(isProductionEnv ? FireProd : FireDev);
const Storage = {
  deleteObject,
  getDownloadURL,
  getMetadata,
  listAll,
  ref: refPath => ref(StorageInit, refPath),
  updateMetadata,
  uploadBytesResumable,
  uploadBytes,
};

export {Auth, Firestore, Functions, RemoteConfig, Storage, isProductionEnv};
