/* eslint-disable max-len */
import React from 'react';
import {SvgIcon} from '@mui/material';

const PieceDetails = (props) => (
  <SvgIcon viewBox='0 0 512 512' {...props}>
    <path d="m340.622 85.898-176.351 78.158 81.062 35.928 176.349-78.16z" />
    <path d="m137.948 152.39 176.352-78.158-60.323-26.732a21.333 21.333 0 0 0 -17.288 0l-167.7 74.327z" />
    <path d="m256 406.777v35.5l31.714-14.055a106.193 106.193 0 0 1 -31.714-21.445z" />
    <path d="m256 218.59v35.966a106.634 106.634 0 0 1 181.333 76.11v-192.446z" />
    <path d="m234.667 284.374v-65.784l-181.334-80.37v209.806a21.332 21.332 0 0 0 12.69 19.5l168.643 74.742v-65.308a106.788 106.788 0 0 1 .001-92.586z" />
    <path d="m466.208 451.125-68.181-68.181a85.5 85.5 0 1 0 -15.083 15.083l68.181 68.181a10.666 10.666 0 0 0 15.083-15.083zm-180.8-75.2a64 64 0 1 1 45.255 18.745 63.582 63.582 0 0 1 -45.252-18.748z" />
  </SvgIcon>
);

export default PieceDetails;
