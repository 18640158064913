import {combineReducers} from 'redux';
import {AccountViewReducer, DrawerReducer, ModalReducer, ShipmentViewReducer, SnackbarReducer} from './slices';

export default combineReducers({
  accountView: AccountViewReducer,
  drawer: DrawerReducer,
  modal: ModalReducer,
  shipmentView: ShipmentViewReducer,
  snackbar: SnackbarReducer,
});
