import {useCallback, useMemo} from 'react';
import {array, object} from 'yup';
import {Auth, Firestore} from 'firebaseConfig';
import {useUiModal, useUiSnackbar} from 'state';

const useFormProps = () => {
  const {clearModal} = useUiModal();
  const {setSnackbar} = useUiSnackbar();


  const initialStatus = useMemo(() => ({text: ''}), []);

  const initialValues = useMemo(() => ({
    Facility: '',
    FacilityUnit: '',
    Containers: [],
  }), []);

  const validationSchema = useMemo(() => {
    return object().shape({
      Facility: object().required(),
      FacilityUnit: object().label('Facility Unit').required(),
      Containers: array().min(1),
    });
  }, []);

  const onSubmit = useCallback((values, actions) => {
    const batch = Firestore.writeBatch();

    // On new submit, clear Alerts (if any) from previous submit attempts.
    actions.setStatus({text: ''});

    // Returning async function causes Formik to automatically set submitting to false at function completion
    // Using async IIFE to prevent this functionality and allow submitting to remain true until manually set
    (async () => {
      const newFacilityUnit = values?.FacilityUnit;
      const newFacilitySubUnit = values?.FacilityUnit?.SubUnit;

      try {
        // CONTAINER VALIDATION
        const containersAlreadyInFacilityUnit = [];
        const containersWithUnpermittedMaterial = [];
        values.Containers.forEach(container => {
          const currentFacilityUnit = container?.['@Admin-Facility-Units'];

          // SAME FACILITY UNIT VALIDATION
          // Do not allow container to be moved into the facility unit (or sub unit) where its already located
          const isSameFacilityUnit = currentFacilityUnit?.DocId === newFacilityUnit?.DocId;
          const isSameFacilitySubUnit = currentFacilityUnit?.SubUnit === newFacilitySubUnit;
          if (isSameFacilityUnit && isSameFacilitySubUnit) containersAlreadyInFacilityUnit.push(container.ShortNo);

          // CONTAINER MATERIAL VALIDATION
          // Make sure Facility Unit selected is permitted to store container material
          const containerMaterialDocId = container?.['@Admin-Materials']?.DocId;
          const allowedStorageMaterialDocIds = values?.FacilityUnit?.Storage?.MaterialDocIds || [];
          if (!allowedStorageMaterialDocIds.includes(containerMaterialDocId)) containersWithUnpermittedMaterial.push(container.ShortNo);
        });

        // TRIGGER VALIDATION ERRORS
        // Trigger errors related to preventing containers being moved into the Facility Unit where they're currently located
        if (containersAlreadyInFacilityUnit.length === 1) {
          throw Error(`Container ${containersAlreadyInFacilityUnit[0]} is already located in this Facility Unit. Remove this container in order to continue.`);
        }
        else if (containersAlreadyInFacilityUnit.length > 1) {
          throw Error(`Containers ${containersAlreadyInFacilityUnit.join('; ')} are already located in this Facility Unit. Remove these containers in order to continue.`);
        }
        // Trigger errors related to preventing containers with unpermitted Materials from being moved into Facility unit
        if (containersWithUnpermittedMaterial.length === 1) {
          throw Error(`Container ${containersWithUnpermittedMaterial[0]} has a material that is not permitted in this Facility Unit. Remove this container in order to continue.`);
        }
        else if (containersWithUnpermittedMaterial.length > 1) {
          throw Error(`Containers ${containersWithUnpermittedMaterial.join('; ')} have materials that are not permitted in this Facility Unit. Remove these container in order to continue.`);
        }

        // CONTAINER UDPATES
        values.Containers.forEach(container => {
          // UPDATE FACILITY UNIT
          // Update the '@Admin-Facility-Units field in the Inventory-Containers document
          const containerRef = Firestore.doc(`Inventory-Containers/${container?.DocId}`);
          const containerUpdateItem = {
            '@Admin-Facility-Units': {
              DocId: values.FacilityUnit.DocId,
              Name: values.FacilityUnit.Name,
              SubUnit: values.FacilityUnit.SubUnit,
            },
          };
          batch.set(containerRef, containerUpdateItem, {merge: true});

          // ADD CONTAINER HISTORY
          // Add a document to the Inventory-Container-History collection describing the Facility Unit movement
          const currentFacilityUnit = container?.['@Admin-Facility-Units'];
          const containerHistoryRef = Firestore.doc(Firestore.collection('Inventory-Container-History'));
          const containerHistoryItem = {
            DocId: container?.DocId,
            Type: 'Movement Form',
            PreviousUnit: (() => {
              if (!currentFacilityUnit) return 'Staging Area';
              const {Name, SubUnit} = currentFacilityUnit;
              if (Name && SubUnit) return `${Name}, ${SubUnit}`;
              return Name;
            })(),
            NewUnit: (() => {
              const Name = newFacilityUnit?.Name;
              const SubUnit = newFacilitySubUnit;
              if (Name && SubUnit) return `${Name}, ${SubUnit}`;
              return Name;
            })(),
            EnteredBy: Auth.currentUser().displayName,
            EnteredOn: new Date(),
          };
          batch.set(containerHistoryRef, containerHistoryItem, {merge: true});
        });

        await batch.commit();

        // On successful write, close and clear modal.
        clearModal();
        setSnackbar({open: true, message: `Successfully moved containers`});
      }
      catch (error) {
        actions.setSubmitting(false);
        actions.setStatus({text: error.message, severity: 'error'});
      }
    })();
  }, [clearModal, setSnackbar]);

  return {
    initialStatus,
    initialValues,
    validationSchema,
    onSubmit,
  };
};

export default useFormProps;
