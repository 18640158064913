import {AutoCompleteObject} from '@kbi/story-forms';
import {useSelector} from 'react-redux';
import {useFormikContext} from 'formik';
import {useEffect, useState} from 'react';

const FacilityUnitField = () => {
  const {active: activeFacilityUnits} = useSelector(state => state.admin.facilityUnits);
  const {values} = useFormikContext();
  const [fieldOptions, setFieldOptions] = useState({data: [], pending: true});

  const facilityDocId = values?.Facility?.DocId || null;
  useEffect(() => {
    let options = [...activeFacilityUnits];

    // When Facility has been selected, filter the Facility Units by that primary Facility DocId
    if (facilityDocId) options = options.filter(option => option?.['@Admin-Kbi-Locations']?.DocId === facilityDocId);

    // Merge Unit and SubUnit into a single list of options
    const mergedOptions = [];
    options.forEach(option => {
      const {DocId, Name, Storage} = option;
      if (option?.SubUnits) {
        option.SubUnits.forEach(SubUnit => {
          mergedOptions.push({DocId, Name, Storage, SubUnit, Merge: `${Name}, ${SubUnit}`});
        });
      }
      else {
        mergedOptions.push({DocId, Name, Storage, SubUnit: null, Merge: Name});
      }
    });

    // Sort the the Merge property alphabetically and then set to fieldOptions state
    const sortedOptions = mergedOptions.sort((a, b) => a?.Merge >= b?.Merge ? 1 : -1);
    setFieldOptions({data: sortedOptions, pending: false});
  }, [activeFacilityUnits, facilityDocId]);

  return (
    <AutoCompleteObject
      label='Facility Unit'
      loading={fieldOptions.pending}
      name='FacilityUnit'
      options={fieldOptions.data}
      optionKey='Merge'
      required
    />
  );
};

export default FacilityUnitField;
