import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {AutoCompleteObject} from '@kbi/story-forms';
import {useFormikContext} from 'formik';

const ContainersField = () => {
  const {all: fieldOptions} = useSelector(state => state.inventory.onsite);
  const {setFieldValue} = useFormikContext();

  const handleChange = ({field}) => {
    // Do not allow containers being used on other forms to be added to this movement form
    const containerValues = [...(field?.value || [])];
    const addedContainer = containerValues.pop();

    if (addedContainer?.InUse) {
      alert(`Cannot add ${addedContainer.ShortNo} to movement form, it is currently in use on form ${addedContainer?.InUse?.FormNo}`);
      setFieldValue('Containers', containerValues);
    }
  };

  const sortedFieldOptions = useMemo(() => {
    return [...fieldOptions].sort((a, b) => {
      if (a.ShortNo > b.ShortNo) return 1;
      else if (a.ShortNo < b.ShortNo) return -1;
      else return 0;
    });
  }, [fieldOptions]);

  return (
    <AutoCompleteObject
      name='Containers'
      multiple
      options={sortedFieldOptions}
      optionKey='ShortNo'
      required
      onChange={handleChange}
    />
  );
};

export default ContainersField;
