import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  container: {data: null, pending: true},
  containerHistory: [],
  inventory: {data: null, pending: true},
  selectedInventory: null,
  selectedInventoryHistory: {data: null, pending: true},
};

const containerDetailsSlice = createSlice({
  name: 'containerDetails',
  initialState,
  reducers: {
    setContainer: (state, action) => {
      state.container = {...state.container, ...action.payload};
    },
    setContainerHistory: (state, action) => {
      state.containerHistory = [...action.payload];
    },
    setInventory: (state, action) => {
      state.inventory = {...state.inventory, ...action.payload};
    },
    setSelectedInventory: (state, action) => {
      state.selectedInventory = action.payload;
    },
    setSelectedInventoryHistory: (state, action) => {
      state.selectedInventoryHistory = action.payload;
    },
    resetInitialState: (state, action) => {
      state.container = initialState.container;
      state.containerHistory = initialState.containerHistory;
      state.inventory = initialState.inventory;
      state.selectedInventory = initialState.selectedInventory;
      state.selectedInventoryHistory = initialState.selectedInventoryHistory;
    },
  },
});

export const containerDetailsActions = containerDetailsSlice.actions;

export default containerDetailsSlice.reducer;
