import {combineReducers} from 'redux';
import {FacilityUnitsReducer, KbiLocationsReducer, MaterialsReducer, ProcessesReducer} from './slices';


export default combineReducers({
  facilityUnits: FacilityUnitsReducer,
  kbiLocations: KbiLocationsReducer,
  materials: MaterialsReducer,
  processes: ProcessesReducer,
});
