import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {List, ListItem, Button} from '@mui/material';

const DetailsButtons = props => {
  // Default Props
  const buttons = props?.buttons || [];

  const buttonProps = useCallback(button => {
    const {onClick, disabled=false, startIcon=undefined, endIcon=undefined} = button;
    return {
      color: 'secondary',
      disabled,
      endIcon,
      fullWidth: true,
      startIcon,
      variant: 'outlined',
      onClick: (e) => onClick(e),
    };
  }, []);

  if (buttons.length === 0) return null;
  return (
    <List sx={styles}>
      {buttons.filter(button => button.visible !== false).map((button, index) => (
        <ListItem disableGutters disablePadding key={`${button.text}-${index}`}>
          <Button {...buttonProps(button)}>{button.text}</Button>
        </ListItem>
      ))}
    </List>
  );
};

const styles = {
  'width': '100%',
  '& .MuiListItem-root': {
    marginTop: '8px',
  },
};
DetailsButtons.propTypes = {
  /** An array of objects mapped over for each of the sections in the list. any props not specified here that are passed will be attached to the MaterialUI Button component  */
  buttons: PropTypes.arrayOf(PropTypes.shape({
  /** The label for the button */
    text: PropTypes.string.isRequired,
    /** The function ran on click of the button */
    onClick: PropTypes.func.isRequired,
    /** A boolean to disabled the button. defaults to false */
    disabled: PropTypes.bool,
    /** A boolean to hide or show the button */
    visable: PropTypes.bool,
  })).isRequired,
};
export default DetailsButtons;
