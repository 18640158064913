import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  all: null,
  active: null,
  profiled: null,
  pending: true,
  touched: false,
  map: null,
};

const materialsSlice = createSlice({
  name: 'materials',
  initialState,
  reducers: {
    setAll: (state, action) => {
      state.all = action.payload;
    },
    setActive: (state, action) => {
      state.active = action.payload;
    },
    setProfiled: (state, action) => {
      state.profiled = action.payload;
    },
    setPending: (state, action) => {
      state.pending = action.payload;
    },
    setTouched: (state, action) => {
      state.touched = action.payload;
    },
    setMap: (state, action) => {
      state.map = action.payload;
    },
    forgetState: (state, action) => {
      state.all = initialState.all;
      state.active = initialState.active;
      state.profiled = initialState.profiled;
      state.pending = initialState.pending;
      state.touched = initialState.touched;
      state.map = initialState.map;
    },
  },
});

export const materialsActions = materialsSlice.actions;

export default materialsSlice.reducer;
