import {useDispatch, useSelector} from 'react-redux';
import {shipmentViewActions, selectors} from 'state/ui/slices/shipmentView';

const useUiShipmentView = () => {
  const dispatch = useDispatch();
  const {activeSelector} = useSelector(state => state.ui.shipmentView);

  const setActiveSelector = payload => dispatch(shipmentViewActions.setActiveSelector(payload));

  return {activeSelector, setActiveSelector, selectors};
};

export default useUiShipmentView;
