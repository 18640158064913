import {combineReducers} from 'redux';
import {AdminProcessDetailsReducer} from './slices';
import {AccountDetailsReducer, ConsolidateDetailsReducer, ContainerDetailsReducer} from './slices';
import {InboundDetailsReducer, InboundPieceDetailsReducer, ProcessDetailsReducer} from './slices';
import {OutboundDetailsReducer, AddMaterialDetailsReducer, ProfileDetailsReducer} from './slices';
import {ProformaDetailsReducer, PurchaseOrderDetailsReducer, QuickSortDetailsReducer, SortDetailsReducer} from './slices';

export default combineReducers({
  addMaterialDetails: AddMaterialDetailsReducer,
  adminProcessDetails: AdminProcessDetailsReducer,
  accountDetails: AccountDetailsReducer,
  consolidateDetails: ConsolidateDetailsReducer,
  containerDetails: ContainerDetailsReducer,
  inboundDetails: InboundDetailsReducer,
  inboundPieceDetails: InboundPieceDetailsReducer,
  outboundDetails: OutboundDetailsReducer,
  processDetails: ProcessDetailsReducer,
  profileDetails: ProfileDetailsReducer,
  proformaDetails: ProformaDetailsReducer,
  purchaseOrderDetails: PurchaseOrderDetailsReducer,
  sortFullDetails: SortDetailsReducer,
  sortQuickDetails: QuickSortDetailsReducer,
});
