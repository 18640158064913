import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  quickSortForm: {data: null, pending: true},
  inboundContainers: {data: null, pending: true},
};

const sortQuickDetailsSlice = createSlice({
  name: 'sortQuickDetails',
  initialState,
  reducers: {
    setQuickSortForm: (state, action) => {
      state.quickSortForm = {...state.quickSortForm, ...action.payload};
    },
    setInboundContainers: (state, action) => {
      state.inboundContainers = {...state.inboundContainers, ...action.payload};
    },
    resetInitialState: (state, action) => {
      state.quickSortForm = initialState.quickSortForm;
      state.inboundContainers = initialState.inboundContainers;
    },
  },
});

export const sortQuickDetailsActions = sortQuickDetailsSlice.actions;

export default sortQuickDetailsSlice.reducer;
