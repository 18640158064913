import {configureStore} from '@reduxjs/toolkit';

// Import redux reducers
import AccountingReducer from './accounting/slice';
import AccountsReducer from './accounts/slice';
import AdminReducers from './admin/sliceReducer';
import AuthReducers from './auth/slice';
import DashboardReducers from './dashboard/sliceReducer';
import IndexesReducer from './indexes/sliceReducer';
import InventoryReducers from './inventory/sliceReducer';
import ProductionReducers from './production/slice';
import ShipmentsReducer from './shipments/slice';
import UiReducers from './ui/sliceReducer';
import ViewsReducers from './views/sliceReducer';

// Initialize Redux Store
const store = configureStore({
  reducer: {
    accounting: AccountingReducer,
    accounts: AccountsReducer,
    admin: AdminReducers,
    auth: AuthReducers,
    dashboard: DashboardReducers,
    indexes: IndexesReducer,
    inventory: InventoryReducers,
    production: ProductionReducers,
    shipments: ShipmentsReducer,
    ui: UiReducers,
    views: ViewsReducers,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    // Turn off serializable check in develepment mode to prevent console warning and slowdown when state is very large
    // https://redux-toolkit.js.org/api/serializabilityMiddleware
    serializableCheck: {
      ignoredActions: ['modal/setModal'],
      ignoredPaths: ['ui.modal.item'],
    },
  }),
});

export default store;
