import React, {useEffect, useState} from 'react';
import {MenuItem} from '@mui/material';
import {SelectField} from '@kbi/story-forms';
import {Firestore} from 'firebaseConfig';
import {useUiModal} from 'state';
import {useFormikContext} from 'formik';

const FacilityLocationField = () => {
  const [options, setOptions] = useState({data: [], pending: true});
  const {modal} = useUiModal();
  const {setFieldValue} = useFormikContext();

  useEffect(() => {
    (async () => {
      try {
        const data = await getOptions();
        setOptions({data, pending: false});

        const foundLocation = data.find(option => {
          return option.DocId === modal?.item?.['@Admin-Kbi-Locations']?.DocId;
        });
        setFieldValue('Facility', foundLocation || data[0]);
      }
      catch (error) {
        alert('An error occured while loading KBI locations');
        console.log('KBI locations error:', {error});
        setOptions({data: [], pending: false});
      }
    })();
  }, [modal?.item, setFieldValue]);

  return (
    <SelectField name='Facility' required>
      {options.data.map(facility => <MenuItem value={facility} key={facility.Name}>{facility.Name}</MenuItem>)}
    </SelectField>
  );
};

const getOptions = async () => {
  try {
    const options = [];
    const kbiLocationQuery = Firestore.query(
      Firestore.collection(`Admin-Kbi-Locations`),
      Firestore.orderBy('Name', 'asc')
    );
    const snap = await Firestore.getDocs(kbiLocationQuery);
    snap.forEach(doc => options.push({...doc.data(), DocId: doc.id}));
    return options;
  }
  catch (error) {
    throw Error(error.message);
  }
};
export default FacilityLocationField;
