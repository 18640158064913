/* eslint-disable max-len */
import React from 'react';
import {SvgIcon} from '@mui/material';

const Materials = (props) => (
  <SvgIcon viewBox='0 0 128 128' {...props}>
    <g>
      <path d="m122.75 64a5.018 5.018 0 0 0 -3.272-4.69v-44.348a5.012 5.012 0 0 0 -1.75-9.712h-69.456a5.012 5.012 0 0 0 -1.75 9.712v15.967h-37.568a1.749 1.749 0 0 0 -1.75 1.75v10.38c-.451 2.162-4.032 20.989.011 55.9 1.872 16.162-1.55 20.911-1.557 20.923a1.75 1.75 0 0 0 1.342 2.868h110.728a5.012 5.012 0 0 0 1.75-9.712v-44.348a5.018 5.018 0 0 0 3.272-4.69zm-58.011 48.706a63.764 63.764 0 0 1 .327-13.752 271.361 271.361 0 0 0 1.9-29.932h49.013v43.684zm2.216-47.184c-.013-1.055-.035-2.062-.061-3.044h50.834a1.522 1.522 0 1 1 0 3.044zm-18.683-56.772h69.456a1.522 1.522 0 1 1 0 3.044h-69.456a1.522 1.522 0 1 1 0-3.044zm1.75 6.544h65.956v43.684h-49.212a107.242 107.242 0 0 0 -1.688-15.917v-10.382a1.75 1.75 0 0 0 -1.75-1.75h-13.306zm-39.322 19.135h50.878v7.064h-50.878zm-.924 84.821c1.105-3.2 2.22-9.446.916-20.7-3.512-30.324-1.112-48.613-.282-53.557h51.463c.832 4.934 3.236 23.171-.284 53.559-1.3 11.253-.188 17.5.916 20.7zm107.952 0h-51.441a14.857 14.857 0 0 1 -.988-3.044h52.429a1.522 1.522 0 1 1 0 3.044z" />
      <path d="m36.141 67.237a14.885 14.885 0 1 0 14.884 14.885 14.9 14.9 0 0 0 -14.884-14.885zm0 26.269a11.385 11.385 0 1 1 11.384-11.384 11.4 11.4 0 0 1 -11.384 11.384z" />
    </g>
  </SvgIcon>
);

export default Materials;
