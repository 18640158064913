import {useDispatch, useSelector} from 'react-redux';
import {accountViewActions} from 'state/ui/slices/accountView';

const useUiAccountView = () => {
  const dispatch = useDispatch();
  const {Contacts, Locations, Notes, Documents, Profiles, POs, Shipments} = useSelector(state => state.ui.accountView);

  const setAccountView = payload => dispatch(accountViewActions.setAccountView(payload));

  return {setAccountView, Contacts, Locations, Notes, Documents, Profiles, POs, Shipments};
};

export default useUiAccountView;
