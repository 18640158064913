import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  all: null,
  inbound: null,
  sortPending: null,
  watchlist: null,
  pending: true,
  touched: false,
};

const onsiteSlice = createSlice({
  name: 'onsite',
  initialState,
  reducers: {
    setAll: (state, action) => {
      state.all = action.payload;
    },
    setInbound: (state, action) => {
      state.inbound = action.payload;
    },
    setSortPending: (state, action) => {
      state.sortPending = action.payload;
    },
    setWatchlist: (state, action) => {
      state.watchlist = action.payload;
    },
    setPending: (state, action) => {
      state.pending = action.payload;
    },
    setTouched: (state, action) => {
      state.touched = action.payload;
    },
    forgetState: (state, action) => {
      state.all = initialState.all;
      state.inbound = initialState.inbound;
      state.sortPending = initialState.sortPending;
      state.watchlist = initialState.watchlist;
      state.pending = initialState.pending;
      state.touched = initialState.touched;
    },
  },
});

export const onsiteActions = onsiteSlice.actions;

export default onsiteSlice.reducer;
