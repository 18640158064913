import {DateTime} from 'luxon';

/*
 * Function is used to make sure all dates are normalized to 12:00:00 AM PST; If offset difference not added,
 * an entry on the East Coast (12:00:00 am EST) will show as previous day on West Coast (09:00:00 pm PST). Also,
 * if dst (daylight savings time) difference not added, then dates set in the future with different dst may be
 * set one hour in the past or one hour in the future
*/

const dateIsoNormalize = (isoDate) => {
  const LocalDateTime = DateTime.fromISO(isoDate);
  const PstDate = DateTime.now().setZone('America/Los_Angeles');

  const timezoneDiff = (LocalDateTime.offset - PstDate.offset);
  const dstDiff = (() => {
    if (LocalDateTime.isInDST === PstDate.isInDST) return 0;
    else if (!LocalDateTime.isInDST && PstDate.isInDST) return 60;
    else if (LocalDateTime.isInDST && !PstDate.isInDST) return -60;
  })();

  return LocalDateTime.plus({minutes: timezoneDiff + dstDiff}).toJSDate();
};

export default dateIsoNormalize;
