import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  profile: {data: null, pending: true},
  contacts: {data: null, pending: true},
  locations: {data: null, pending: true},
  adminMaterials: {data: null, pending: true},
  availableMaterials: [],
  addedMaterials: [],
};

const profileDetailsSlice = createSlice({
  name: 'profileDetails',
  initialState,
  reducers: {
    setProfile: (state, action) => {
      state.profile = {...state.profile, ...action.payload};
    },
    setContacts: (state, action) => {
      state.contacts = {...state.contacts, ...action.payload};
    },
    setLocations: (state, action) => {
      state.locations = {...state.locations, ...action.payload};
    },
    setAdminMaterials: (state, action) => {
      state.adminMaterials = {...state.adminMaterials, ...action.payload};
    },
    setAvailableMaterials: (state, action) => {
      state.availableMaterials = [...action.payload];
    },
    setAddedMaterials: (state, action) => {
      state.addedMaterials = [...action.payload];
    },
    resetInitialState: (state, action) => {
      state.profile = {data: null, pending: true};
      state.contacts = {data: null, pending: true};
      state.locations = {data: null, pending: true};
      state.adminMaterials = {data: null, pending: true};
      state.availableMaterials = [];
      state.addedMaterials = [];
    },
  },
});

export const profileDetailsActions = profileDetailsSlice.actions;

export default profileDetailsSlice.reducer;
