import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DateTime} from 'luxon';
import {Auth, Firestore} from 'firebaseConfig';
import {onsiteActions} from 'state/inventory/slices/onsite';
const snapOptions = {includeMetadataChanges: true};

const useOnsiteInventory = () => {
  const {all, pending, touched} = useSelector(state => state.inventory.onsite);
  const dispatch = useDispatch();

  useEffect(() => {
    // Only run remainder of code if hook untouched; Prevents duplicate invocations;
    if (touched) return;
    dispatch(onsiteActions.setTouched(true));

    let unsubscribe = null;
    // Make sure user is authenticated before invoking listener
    Auth.onAuthStateChanged(user => {
      // USER NOT AUTHENTICATED
      // When user is no longer authenticate, clear redux state and unsubscribe to listener
      if (!user) {
        dispatch(onsiteActions.forgetState());
        if (unsubscribe) unsubscribe();
      }

      // USER IS AUTHENTICATED
      // When user is authenticated, create snapshot listener and add data results to redux state
      const query = Firestore.query(
        Firestore.collection('Inventory-Containers'),
        Firestore.where('Onsite', '==', true),
      );
      const snapData = snap => {
        const {fromCache, hasPendingWrites} = snap?.metadata || {};
        if (fromCache || hasPendingWrites) return;

        const all = [];
        const inbound = [];
        const sortPending = [];
        const watchlist = [];
        snap.forEach(doc => {
          const docData = doc.data();
          const cleanData = {
            ...docData,
            DocId: doc.id,
            AccumStartDate: docData?.AccumStartDate?.toMillis ? docData.AccumStartDate.toMillis() : null,
            Inbound: (() => {
              if (!docData?.Inbound) return null;
              return {
                ...docData.Inbound,
                TimeReceived: docData.Inbound.TimeReceived.toMillis(),
              };
            })(),
            System: {
              ...docData.System,
              EnteredOn: docData?.System?.EnteredOn?.toMillis ? docData.System.EnteredOn.toMillis() : null,
            },
          };
          // Every doc added to all containers list
          all.push(cleanData);
          // Add doc to sub list of inbound containers, as appropriate
          if (cleanData.Inbound) inbound.push(cleanData);
          // Add doc to sub list of containers pending sort
          if (cleanData?.Inbound?.['@Inbound-Pieces']?.PendingSort) sortPending.push(cleanData);
          // Add doc to sub list of containers on accum. start date anniversay watchlist
          const accumAnniversary = DateTime.fromMillis(cleanData?.AccumStartDate).plus({year: 1}).endOf('day');
          const DaysRemaining = Math.floor(accumAnniversary.diffNow('days').days);
          if (DaysRemaining <= 30) watchlist.push({...cleanData, DaysRemaining, Category: '30 days'});
          else if (DaysRemaining <= 60) watchlist.push({...cleanData, DaysRemaining, Category: '60 days'});
          else if (DaysRemaining <= 90) watchlist.push({...cleanData, DaysRemaining, Category: '90 days'});
        });

        dispatch(onsiteActions.setAll(all));
        dispatch(onsiteActions.setInbound(inbound));
        dispatch(onsiteActions.setSortPending(sortPending));
        dispatch(onsiteActions.setWatchlist(watchlist));
        dispatch(onsiteActions.setPending(false));
      };
      const snapError = error => {
        if (error.message !== 'Missing or insufficient permissions.') alert(error.message);
        console.log('useOnsiteInventory Error: ', error.message, {error});
      };
      unsubscribe = Firestore.onSnapshot(query, snapOptions, snapData, snapError);
    });
  }, [dispatch, touched]);

  return {all, pending, touched};
};

export default useOnsiteInventory;
