import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  addMaterialForm: {data: null, pending: true},
  addedMaterials: {data: null, pending: true},
};

const addMaterialSlice = createSlice({
  name: 'addMaterialDetails',
  initialState,
  reducers: {
    setKbiMaterialForm: (state, action) => {
      state.addMaterialForm = {...state.addMaterialForm, ...action.payload};
    },
    setAddedMaterials: (state, action) => {
      state.addedMaterials = {...state.addedMaterials, ...action.payload};
    },
    resetInitialState: (state, action) => {
      state.addMaterialForm = initialState.addMaterialForm;
      state.addedMaterials = initialState.addedMaterials;
    },
  },
});

export const addMaterialDetailsActions = addMaterialSlice.actions;

export default addMaterialSlice.reducer;
