import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  shipmentsActiveInbound: {data: [], pending: true},
  shipmentsInactiveInbound: {data: [], pending: true},
  shipmentsActiveOutbound: {data: [], pending: true},
  shipmentsInactiveOutbound: {data: [], pending: true},
  touched: false,
};

const shipmentsSlice = createSlice({
  name: 'shipments',
  initialState,
  reducers: {
    setShipmentsActiveInbound: (state, action) => {
      state.shipmentsActiveInbound = action.payload;
    },
    setShipmentsInactiveInbound: (state, action) => {
      state.shipmentsInactiveInbound = action.payload;
    },
    setShipmentsActiveOutbound: (state, action) => {
      state.shipmentsActiveOutbound = action.payload;
    },
    setShipmentsInactiveOutbound: (state, action) => {
      state.shipmentsInactiveOutbound = action.payload;
    },
    setTouched: (state, action) => {
      state.touched = action.payload;
    },
    forgetState: (state, action) => {
      state.shipmentsActiveInbound = initialState.shipmentsActiveInbound;
      state.shipmentsInactiveInbound = initialState.shipmentsInactiveInbound;
      state.shipmentsActiveOutbound = initialState.shipmentsActiveOutbound;
      state.shipmentsInactiveOutbound = initialState.shipmentsInactiveOutbound;
      state.touched = initialState.touched;
    },
  },
});

export const shipmentsActions = shipmentsSlice.actions;

export default shipmentsSlice.reducer;
