import React from 'react';
import PropTypes from 'prop-types';
import {Paper} from '@mui/material';

const StyledPaper = ({children, variant, ...restProps}) => {
  // Set Default Props
  variant = variant || 'default';

  return (
    <Paper {...restProps} elevation={3} sx={{...styles[variant], ...(restProps?.sx || {})}}>
      {children}
    </Paper>
  );
};

const styles = {
  default: {
    'padding': {xs: '8px', sm: '16px', md: '16px', lg: '16px', xl: '16px'},
    '@media print': {boxShadow: 'none', border: '1px solid lightgray'},
  },
  table: {
    'padding': '0px',
    '@media print': {boxShadow: 'none', border: '1px solid lightgray'},
  },
};
StyledPaper.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOf(['default', 'table']),
};
export default StyledPaper;
