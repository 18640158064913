import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  Contacts: true,
  Locations: true,
  Notes: true,
  Documents: true,
  Profiles: true,
  POs: true,
  Shipments: true,
};

const accountViewSlice = createSlice({
  name: 'accountView',
  initialState,
  reducers: {
    setAccountView: (state, action) => {
      if (typeof action?.payload?.Contacts === 'boolean') state.Contacts = action.payload.Contacts;
      if (typeof action?.payload?.Locations === 'boolean') state.Locations = action.payload.Locations;
      if (typeof action?.payload?.Notes === 'boolean') state.Notes = action.payload.Notes;
      if (typeof action?.payload?.Documents === 'boolean') state.Documents = action.payload.Documents;
      if (typeof action?.payload?.Profiles === 'boolean') state.Profiles = action.payload.Profiles;
      if (typeof action?.payload?.POs === 'boolean') state.POs = action.payload.POs;
      if (typeof action?.payload?.Shipments === 'boolean') state.Shipments = action.payload.Shipments;
    },
  },
});

export const accountViewActions = accountViewSlice.actions;

export default accountViewSlice.reducer;
